import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormdataService } from 'src/app/shared/services/formdata.service';
import { ProductsService } from 'src/app/shared/services/products.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap'
import { ToastrService } from 'ngx-toastr';
//import { WizardComponent } from 'angular-archwizard';
import { ApiService } from 'src/app/shared/services/api.service';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

import { environment } from 'src/environments/environment';


@Component({
    selector: 'app-equipmentform',
    templateUrl: './equipmentform.component.html',
    styleUrls: ['./equipmentform.component.scss']
})

export class EquipmentformComponent implements OnInit {
    baseurl = environment.baseUrl;


    popovername = 'Group Search';
    popovercontent="Group search can be used when all selected items are mandatory in search"
    public blockFields: Array<any> = []; container_type: any;
    blocks: Array<any> = [];
    public displayField: Array<any> = [];
    public childBlocks: Array<any> = [];
    public childProductField: Array<any> = [];
    public processData: Array<any> = [];
    public childBlockName: any;
    blocked: any;
    public child: Boolean = false;
    public processid: Array<any> = [];
    public min: string = "";
    public max: string = "";
    public total: number = 0;
    public newHash: any;
    public fileToUpload: File = null;
    public fileToUploadName:string="";
    public fileStringArray: File = null;
    
    public id: any;
    edit_page: any;
    public type: any;
    public editSection: Boolean = false;
    public valid: Boolean
    public unit: Boolean
    public selectedBlockIndex: Number;
    public data: any = {};
    public send: any = {};
    public percentage: any;
    public query: Array<any> = [];
    public Intequery: Array<any> = [];
    public modalData: any;
    public productImages = [];
    public newRoom = [];
    public ImgUrl: any
    goNext: Boolean = false;
    multiDropdownSettings: any;
    selctedProjects: Array<any>;
    select_category: any;
    public legal_developer_access_formGroup: FormGroup;
    newArray: any[] = [];
    array_name: any;
    coverImg: any = {};
    blockTitle: any;
    blockId: any;
    show: boolean = false;
    edit: boolean = false;
    cotainer_filed: any;
    image_box: any;
  

    constructor(private route: ActivatedRoute,
        private formService: FormdataService,
        private apiservice: ApiService,
        private productService: ProductsService, private fb: FormBuilder,
        private spinner: NgxSpinnerService,
        private cdr: ChangeDetectorRef,
        private router: Router,
        private modalService: NgbModal,
        private config: NgbModalConfig, private tostr: ToastrService) {

        this.legal_developer_access_formGroup = this.fb.group({
            legal_developer_access: this.fb.array([])
        });

        config.backdrop = 'static';
    }

    @ViewChild('content') private content;
    //@ViewChild('wizard') wizard: WizardComponent;

    ngOnInit() {
        this.spinner.show();
        this.iniDropDownSetting();
        this.route.params.subscribe((params) => {
            this.id = params.id
            if (localStorage.getItem('formdata')) {
                this.edit = true;
                this.data = JSON.parse(localStorage.getItem('formdata'));
                this.blockId = this.data.productFields[0].block_id;
                this.show = false;
                this.data.blocks[0]['is_selected'] = true;
                if (params.edit == "edit") {
                   // this.container_type = this.data.productFields.find(x => x.field_name == "Container Type").title;
                   
                    if (this.data.productFields.find(x => x.field_name.toUpperCase() == "CONTAINER TYPE"))
                    {
                        this.container_type = this.data.productFields.find(x => x.field_name.toUpperCase() == "CONTAINER TYPE").title;  
                    }
                    if (this.container_type == "Multiple Choice Dropdown") {
                        this.commonInit();
                    } else {
                        this.commonInit_first();
                    }
                }
            } else {
                this.productService.getProductsFields2(this.id)
                    .subscribe((result) => {
                        if (result.status) {
                            this.data = result.data;
                            this.edit = false;
                            this.blockId = this.data.productFields[0].block_id;
                            console.log("add time", this.data);
                           // this.container_type = this.data.productFields.find(x => x.field_name == "Container Type").title;
                           if (this.data.productFields.find(x => x.field_name.toUpperCase() == "CONTAINER TYPE"))
                           {
                               this.container_type = this.data.productFields.find(x => x.field_name.toUpperCase() == "CONTAINER TYPE").title;  
                           }
                            this.cotainer_filed = this.data.productFields[0];
                            this.commonInit_first();
                        }
                        else {
                            this.tostr.error(result.message);
                            this.router.navigateByUrl('')
                        }
                    });

                    
                  
                    
                this.apiservice._post('product_info', { product_id: params.id })
                    .subscribe((result) => {
                        if (result.status) {
                            this.productImages = result.data;
                            this.coverImg = this.productImages ? this.productImages[0] : '';
                            this.ImgUrl = result.image_url;
                        }
                    })
            } 2
        });
    }

    imageBox(data) {
        this.image_box = data;
    }
    commonInit_first() {
        for (let i = 0; i < this.data.productFields.length - 1; i++) {
            let field = this.data.productFields[i];
            if (field.field_name == this.data.productFields[i + 1].field_name) {
                field.field_value.push(this.data.productFields[i + 1].field_value[0]);
                field.field_value[1].field_id = this.data.productFields[i + 1].field_id
                field.field_value[1].title = this.data.productFields[i + 1].title
                this.data.productFields.splice(i + 1, 1);
            }
        }

        for (let i = 0; i < this.data.integratedProductFields.length; i++) {
            for (let j = 0; j < this.data.integratedProductFields[i].fields.length - 1; j++) {
                let field = this.data.integratedProductFields[i].fields[j];
                if (field.field_name == this.data.integratedProductFields[i].fields[j + 1].field_name) {
                    field.field_value.push(this.data.integratedProductFields[i].fields[j + 1].field_value[0]);
                    field.field_value[1].field_id = this.data.integratedProductFields[i].fields[j + 1].field_id
                    this.data.integratedProductFields[i].fields.splice(j + 1, 1);
                }
            }

        }
        // this.blockFields = this.data.blocks;
        // console.log(this.blockFields[0], "block");
        (this.data.blocks || []).forEach(r => {
            r['is_selected'] = false;
            this.blockFields.push(r);
        });
        let index = this.blockFields.find(y => y.title == "CONTAINER");
        if(index) index['is_selected'] = true;
        if (!this.blockFields.find(y => y.block_id == 0)) {
            this.blockFields.push({
                product_id: 0,
                block_id: 1,
                title: "DOCUMENTS"
            },
                {
                    product_id: 0,
                    block_id: 2,
                    title: "REVIEW"
                })

        }
        if (this.data.product_type == 'Integrated Machine') {
            this.childBlocks = this.data.childBlocks;
            for (let i = 0; i < this.childBlocks.length; i++) {
                this.childBlocks[i].percentage = '';
                // this.childBlocks[i].selected = new Boolean;
            }
            this.processid = this.blockFields.filter(o => o.title.toLowerCase() == 'process');
            if (this.processid[0]) {
                //  console.log("BLOCKFIELDS", this.blockFields)
                if (this.blockFields[0].block_id == this.processid[0].block_id) {
                    this.child = true;
                    // this.childBlocks[0].selected = true;
                    this.childBlockName = this.childBlocks[0].sub_title;
                    this.childProductField = this.data.integratedProductFields.filter(t => t.product_id == this.childBlocks[0].product_id);
                }
                else {
                    this.child = false;
                    var currentHash = window.location.hash;
                    if (currentHash != "") {
                        var re = new RegExp("|" + "parentVerticalTab" + "[0-9]");
                        if (currentHash.match(re) != null) {
                            this.newHash = currentHash.replace(re, '');
                        }
                    }
                    history.replaceState(null, null, this.newHash);
                    this.displayField = this.data.productFields.filter(o => o.block_id == this.blockFields[0].block_id);
                }
            }
            else {
                this.child = false;
                this.displayField = this.data.productFields.filter(o => o.block_id == this.blockFields[0].block_id);
            }

        }

        else if (this.data.product_type == 'Individual Machine') {

            if (this.blockFields) {
                this.selectedBlockIndex = this.blockFields[0].block_id;
                // this.productField = this.data.productFields.filter(o => o.block_id == this.blockFields[0].block_id);
            }
            this.cdr.detectChanges();

        }

        // this.open(this.content);
        // this.integratTab();
        this.spinner.hide();
    }

    commonInit() {
        // (this.data.productFields || []).forEach(r => {
        //     if (r.block_title == "CONTAINER") {
        var fromStorage = ProChartStorage.getItem("test");
        var objectsFromStorage = JSON.parse(fromStorage);
        const creds = this.legal_developer_access_formGroup.controls.legal_developer_access as FormArray;
        objectsFromStorage.legal_developer_access.forEach(pasatiempo => {
            creds.push(this.fb.group({
                height_max: pasatiempo.height_max,
                height_min: pasatiempo.height_min,
                width_max: pasatiempo.width_max,
                width_min: pasatiempo.width_min,
                depth_max: pasatiempo.depth_max,
                depth_min: pasatiempo.depth_min,
                diameter_max: pasatiempo.diameter_max,
                diameter_min: pasatiempo.diameter_min,
                nack_diameter_max: pasatiempo.nack_diameter_max,
                nack_diameter_min: pasatiempo.nack_diameter_min,
                //select_name: pasatiempo.select_name
            }));
            this.newArray.push(creds.value);
        });

        for (let i = 0; i < this.data.integratedProductFields.length; i++) {
            for (let j = 0; j < this.data.integratedProductFields[i].fields.length - 1; j++) {
                let field = this.data.integratedProductFields[i].fields[j];
                if (field.field_name == this.data.integratedProductFields[i].fields[j + 1].field_name) {
                    field.field_value.push(this.data.integratedProductFields[i].fields[j + 1].field_value[0]);
                    field.field_value[1].field_id = this.data.integratedProductFields[i].fields[j + 1].field_id
                    this.data.integratedProductFields[i].fields.splice(j + 1, 1);
                }
            }

        }
        this.blockFields = this.data.blocks;
        if (!this.blockFields.find(y => y.block_id == 0)) {
            this.blockFields.push({
                product_id: 0,
                block_id: 1,
                title: "DOCUMENTS"
            },
                {
                    product_id: 0,
                    block_id: 2,
                    title: "REVIEW"
                })

        }
        if (this.data.product_type == 'Integrated Machine') {
            this.childBlocks = this.data.childBlocks;
            for (let i = 0; i < this.childBlocks.length; i++) {
                this.childBlocks[i].percentage = '';
                // this.childBlocks[i].selected = new Boolean;
            }
            this.processid = this.blockFields.filter(o => o.title.toLowerCase() == 'process');
            if (this.processid[0]) {
                if (this.blockFields[0].block_id == this.processid[0].block_id) {
                    this.child = true;
                    // this.childBlocks[0].selected = true;
                    this.childBlockName = this.childBlocks[0].sub_title;
                    this.childProductField = this.data.integratedProductFields.filter(t => t.product_id == this.childBlocks[0].product_id);
                }
                else {
                    this.child = false;
                    var currentHash = window.location.hash;
                    if (currentHash != "") {
                        var re = new RegExp("|" + "parentVerticalTab" + "[0-9]");
                        if (currentHash.match(re) != null) {
                            this.newHash = currentHash.replace(re, '');
                        }
                    }
                    history.replaceState(null, null, this.newHash);
                    this.displayField = this.data.productFields.filter(o => o.block_id == this.blockFields[0].block_id);
                }
            }
            else {
                this.child = false;
                this.displayField = this.data.productFields.filter(o => o.block_id == this.blockFields[0].block_id);
            }

        }

        else if (this.data.product_type == 'Individual Machine') {

            if (this.blockFields) {
                this.selectedBlockIndex = this.blockFields[0].block_id;
                // this.productField = this.data.productFields.filter(o => o.block_id == this.blockFields[0].block_id);
            }
            this.cdr.detectChanges();

        }

        // this.open(this.content);
        // this.integratTab();
        this.spinner.hide();
    }


    loadDeveloperAccessData = (developerAccessArray: any[] = []): void => {
        developerAccessArray.forEach((developerAccess) => {
            this.getDeveloperAccessFormArray.push(this.createFormGroup());
        });
    }

    createFormGroup = (): FormGroup => {
        return this.fb.group({
            height_max: [''],
            height_min: [''],
            width_max: [''],
            width_min: [''],
            depth_max: [''],
            depth_min: [''],
            diameter_max: [''],
            diameter_min: [''],
            nack_diameter_max: [''],
            nack_diameter_min: [''],
            select_name: [this.newArray],
        });
    }

    addDeveloperAccessFormGroup = (): void => {
        this.getDeveloperAccessFormArray.push(this.createFormGroup());
    }

    get getDeveloperAccessFormArray(): FormArray {
        return this.legal_developer_access_formGroup.get('legal_developer_access') as FormArray;
    }


    onItemDeSelect(obj: any) {
        const index: number = this.newArray.indexOf(obj);
        if (index !== -1) {
            this.newArray.splice(index, 1);
        }
        (this.legal_developer_access_formGroup.get('legal_developer_access') as FormArray).removeAt(obj);
    }

    onItemSelect(obj: any) {
        this.newArray.push(obj);
        this.addDeveloperAccessFormGroup();
    }


    onSelectAll(obj: any) {
    }

    iniDropDownSetting() {
        this.multiDropdownSettings = {
            singleSelection: false,
            idField: 'id',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'Unselect All',
            searchPlaceholderText: 'Search Container Type',
            allowSearchFilter: true,
            itemsShowLimit: 3
        };
    }

    getField(id) {
        return this.data.productFields.filter(o => o.block_id == id)
    }

    checkClass(id) {
        if (id == this.selectedBlockIndex) {
            return "active"
        }
    }

    setTab(i, id) {
        this.selectedBlockIndex = id
    }

    activateEdit(value) {
        if (value == "CONTAINER") {
            this.show = false;
            this.blockId = this.data.blocks[0].block_id;
            for (var k = 0; k < this.blockFields.length; k++) {
                if (this.blockFields[k].title == "CONTAINER") {
                    this.blockFields[k]['is_selected'] = true;
                }
                if (this.blockFields[k].title == "PROCESS" || this.blockFields[k].title == "UTILITIES" || this.blockFields[k].title == "OPTIONS" || this.blockFields[k].title == "DOCUMENTS" || this.blockFields[k].title == "REVIEW") {
                    this.blockFields[k]['is_selected'] = false;
                }
            }
        }
        if (value == "PROCESS") {
            this.blockId = this.data.blocks[1].block_id;
            this.show = false;
            for (var k = 0; k < this.blockFields.length; k++) {
                if (this.blockFields[k].title == "PROCESS") {
                    this.blockFields[k]['is_selected'] = true;
                }
                if (this.blockFields[k].title == "UTILITIES" || this.blockFields[k].title == "OPTIONS" || this.blockFields[k].title == "DOCUMENTS" || this.blockFields[k].title == "REVIEW") {
                    this.blockFields[k]['is_selected'] = false;
                }
            }
        }
        if (value == "UTILITIES") {
            this.blockId = this.data.blocks[2].block_id;
            this.show = false;
            for (var k = 0; k < this.blockFields.length; k++) {
                if (this.blockFields[k].title == "UTILITIES") {
                    this.blockFields[k]['is_selected'] = true;
                }
                if (this.blockFields[k].title == "OPTIONS" || this.blockFields[k].title == "DOCUMENTS" || this.blockFields[k].title == "REVIEW") {
                    this.blockFields[k]['is_selected'] = false;
                }
            }
        }
        if (value == "OPTIONS") {
            this.blockId = this.data.blocks[3].block_id;
            this.show = false;
            for (var k = 0; k < this.blockFields.length; k++) {
                if (this.blockFields[k].title == "OPTIONS") {
                    this.blockFields[k]['is_selected'] = true;
                }
                if (this.blockFields[k].title == "DOCUMENTS" || this.blockFields[k].title == "REVIEW") {
                    this.blockFields[k]['is_selected'] = false;
                }
            }
        }
        if (value == "DOCUMENTS") {
            this.blockId = '1';
            this.show = false;
            for (var k = 0; k < this.blockFields.length; k++) {
                if (this.blockFields[k].title == "DOCUMENTS") {
                    this.blockFields[k]['is_selected'] = true;
                }
                if (this.blockFields[k].title == "REVIEW") {
                    this.blockFields[k]['is_selected'] = false;
                }
            }
        }
    }

    /**
   * upload section of files
   * @param files 
   */
    handleFileInput(files: FileList) {
        this.fileToUpload = files[0];

 
       
       // this.uploadFileToActivity();
    }


    /**
     * upload function 
     */
    uploadFileToActivity_out() {
        this.productService.postFile(this.fileToUpload).subscribe(data => {
            // do something, if upload success
        }, error => {
        });
    }

    uploadFileToActivity() {
        this.productService.postFile(this.fileToUpload).subscribe(data => {
            // do something, if upload success
            console.log("fileUpload Data+--+-+---+-+",data.filename);
            this.fileToUploadName=data.filename
        }, error => {
            console.log(error);
        });
    }
    /**
     * roundoff function
     */
    roundSingle(j) {
        if (this.data.productFields[j].field_value[0].value)
            this.data.productFields[j].field_value[0].value = parseFloat(this.data.productFields[j].field_value[0].value).toFixed(2)
        else {
            this.data.productFields[j].field_value[0].value = ''
        }
    }
    /**
     * roundoff
     */
    roundDouble(j, i) {
        if (this.data.productFields[j].field_value[i].value)
            this.data.productFields[j].field_value[i].value = parseFloat(this.data.productFields[j].field_value[i].value).toFixed(2)
        else {
            this.data.productFields[j].field_value[i].value = ''
        }
    }

    MinMaxVal(j, i) {
        if (this.data.productFields[j].field_value[i].text == "Min") {
            if (i == 0) {
                if (parseFloat(this.data.productFields[j].field_value[0].value) > parseFloat(this.data.productFields[j].field_value[1].value)) {
                    return 'error'
                }
            }
            if (i == 1) {
                if (parseFloat(this.data.productFields[j].field_value[0].value) < parseFloat(this.data.productFields[j].field_value[1].value)) {
                    return 'error'
                }
            }
        }
        if (this.data.productFields[j].field_value[i].text == "Max") {
            if (i == 0) {
                if (parseFloat(this.data.productFields[j].field_value[0].value) < parseFloat(this.data.productFields[j].field_value[1].value)) {
                    return 'error'
                }
            }

            if (i == 1) {
                if (parseFloat(this.data.productFields[j].field_value[0].value) > parseFloat(this.data.productFields[j].field_value[1].value)) {
                    return 'error'
                }
            }
        }

    }

    setTwoNumberDecimal($event) {
        $event.target.value = parseFloat($event.target.value).toFixed(2);
    }

    container_field(data) {
        (this.legal_developer_access_formGroup.value.legal_developer_access || []).forEach(r => {
            if (parseFloat(r.height_min) > parseFloat(r.height_max)) {
                this.tostr.error("Minimum Value should be less than Maximum Value");
                return 'error'
            }
            if (parseFloat(r.width_min) > parseFloat(r.width_max)) {
                this.tostr.error("Minimum Value should be less than Maximum Value");
                return 'error'
            }
            if (parseFloat(r.depth_min) > parseFloat(r.depth_max)) {
                this.tostr.error("Minimum Value should be less than Maximum Value");
                return 'error'
            }
            if (parseFloat(r.diameter_min) > parseFloat(r.diameter_max)) {
                this.tostr.error("Minimum Value should be less than Maximum Value");
                return 'error'
            }
            if (parseFloat(r.nack_diameter_min) > parseFloat(r.nack_diameter_max)) {
                this.tostr.error("Minimum Value should be less than Maximum Value");
                return 'error'
            }
        });
    }

    open(content) {
        this.modalData = this.modalService.open(content);
    }
    /**
   * submit function
   */
    Submit() {

        this.productService.postFileBuyer(this.fileToUpload).subscribe(data=> {
            // do something, if upload success
            console.log("fileUpload Data+--+-+---+-+",data.filename);
            this.data.file_name = this.fileToUpload?data.filename:""; 
            
       

        // to change the changed structure to the previous structure for productifields(individual)
        console.log("DATA BEFORE CHANGEEE", this.data);
      //  this.container_type = this.data.productFields.find(x => x.field_name == "Container Type").title;
      
      if (this.data.productFields.find(x => x.field_name.toUpperCase() == "CONTAINER TYPE"))
      {
          this.container_type = this.data.productFields.find(x => x.field_name.toUpperCase() == "CONTAINER TYPE").title;  
      }
        (this.data.productFields || []).forEach(r => {
            if (r.block_title == "CONTAINER") {
                if (r.field_name == "Container Height") {
                    this.query.push(r);
                } else if (r.field_name == 'Container Width') {
                    this.query.push(r);
                } else if (r.field_name == 'Container Depth') {
                    this.query.push(r);
                } else if (r.field_name == 'Container Diameter') {
                    this.query.push(r);
                } else if (r.field_name == 'Container Neck Diameter') {
                    this.query.push(r);
                } else if (r.field_name == 'Container Type' ? r.field_name == 'Container Type' : r.field_name == 'Container Material') {
                    if (r.field_value.length > 1) {
                        for (let k = 0; k < r.field_value.length; k++) {
                            let subfield = r.field_value[k];
                            if (subfield.text == 'Max') {
                                this.query.push({
                                    "field_id": r.field_id,
                                    "block_id": r.block_id,
                                    "field_name": r.field_name,
                                    "field_type": r.field_type,
                                    "input_name": r.input_name,
                                    "title": r.title,
                                    "values": [],
                                    "field_value": [
                                        {
                                            "text": "Max",
                                            "value": subfield.value
                                        }
                                    ]
                                });

                                let subfieldMax = r.field_value[k + 1];
                                this.query.push({
                                    "field_id": subfieldMax.field_id,
                                    "block_id": r.block_id,
                                    "field_name": r.field_name,
                                    "field_type": r.field_type,
                                    "input_name": r.input_name,
                                    "title": subfieldMax.title,
                                    "values": [],
                                    "field_value": [
                                        {
                                            "text": "Min",
                                            "value": subfieldMax.value,
                                            "field_id": subfieldMax.field_id + 1,
                                            "title": subfieldMax.title
                                        }
                                    ]
                                });

                            }
                        }
                    } else {
                        this.query.push(r);
                    }
                }
            } else {
                if (r.field_value.length > 1) {
                    for (let k = 0; k < r.field_value.length; k++) {
                        let subfield = r.field_value[k];
                        if (subfield.text == 'Max') {
                            this.query.push({
                                "field_id": r.field_id,
                                "block_id": r.block_id,
                                "field_name": r.field_name,
                                "field_type": r.field_type,
                                "input_name": r.input_name,
                                "title": r.title,
                                "values": [],
                                "field_value": [
                                    {
                                        "text": "Max",
                                        "value": subfield.value
                                    }
                                ]
                            });

                            let subfieldMax = r.field_value[k + 1];
                            this.query.push({
                                "field_id": subfieldMax.field_id,
                                "block_id": r.block_id,
                                "field_name": r.field_name,
                                "field_type": r.field_type,
                                "input_name": r.input_name,
                                "title": subfieldMax.title,
                                "values": [],
                                "field_value": [
                                    {
                                        "text": "Min",
                                        "value": subfieldMax.value,
                                        "field_id": subfieldMax.field_id + 1,
                                        "title": subfieldMax.title
                                    }
                                ]
                            });

                        }
                    }
                } else {
                    this.query.push(r);
                }
            }
        });
        this.data.productFields = this.query.slice();
        // structure change for integrated product list

        for (let i = 0; i < this.data.integratedProductFields.length - 1; i++) {
            for (let j = 0; j < this.data.integratedProductFields[i].fields.length - 1; j++) {

                let field = this.data.integratedProductFields[i].fields[j];
                // console.log("FIELD", field)
                if (field.field_value.length > 1) {
                    for (let k = 0; k < field.field_value.length; k++) {
                        let subfield = field.field_value[k];
                        if (subfield.text == 'Max') {
                            this.Intequery.push({
                                "field_id": field.field_id,
                                "block_id": field.block_id,
                                "field_name": field.field_name,
                                "field_type": field.field_type,
                                "input_name": field.input_name,
                                "title": field.title,
                                "values": [],
                                "field_value": [
                                    {
                                        "text": "Max",
                                        "value": subfield.value
                                    }
                                ]
                            });

                            let subfieldMax = field.field_value[k + 1];
                            this.Intequery.push({
                                "field_id": subfieldMax.field_id,
                                "block_id": field.block_id,
                                "field_name": field.field_name,
                                "field_type": field.field_type,
                                "input_name": field.input_name,
                                "title": subfieldMax.title,
                                "values": [],
                                "field_value": [
                                    {
                                        "text": "Min",
                                        "value": subfieldMax.value
                                    }
                                ]
                            });

                        }
                    }
                } else {
                    this.Intequery.push(field);
                }
            }
            console.log("DEBUG", this.Intequery, this.data.integratedProductFields[i].fields)
            // debugger;

            this.data.integratedProductFields[i].fields = this.Intequery.slice();
            this.Intequery = [];
        }
        let docId = this.data.blocks.findIndex(o => o.title == 'DOCUMENTS');
      if (docId>0){
        this.data.blocks.splice(docId, 2);
      }
        
       // this.container_type = this.data.productFields.find(x => x.field_name == "Container Type").title;
      
        if (this.data.productFields.find(x => x.field_name.toUpperCase() == "CONTAINER TYPE"))
        {
            this.container_type = this.data.productFields.find(x => x.field_name.toUpperCase() == "CONTAINER TYPE").title;  
        }
      
        if (this.container_type == "Multiple Choice Dropdown") {
            (this.data.productFields || []).forEach(r => {
                if (r.field_name == "Container Height") {
                    (r.field_value || []).forEach(data => {
                        if (data.text == 'Max') {
                            data.value = Array.prototype.map.call(data.value.legal_developer_access, s => s.height_max).toString();
                            var lastChar = data.value.slice(-1);
                            if (lastChar == ',') {
                                data.value = data.value.slice(0, -1);
                            }
                            //data.value = data.value.replace(/,\s*$/, "");
                        } else if (data.text == 'Min') {
                            data.value = Array.prototype.map.call(data.value.legal_developer_access, s => s.height_min).toString();
                            var lastChar = data.value.slice(-1);
                            if (lastChar == ',') {
                                data.value = data.value.slice(0, -1);
                            }
                            //data.value = data.value.replace(/,\s*$/, "");
                        }
                    });
                } else if (r.field_name == 'Container Width') {
                    (r.field_value || []).forEach(data => {
                        if (data.text == 'Max') {
                            data.value = Array.prototype.map.call(data.value.legal_developer_access, s => s.width_max).toString();
                            var lastChar = data.value.slice(-1);
                            if (lastChar == ',') {
                                data.value = data.value.slice(0, -1);
                            }
                            // data.value = data.value.replace(/,\s*$/, "");
                        } else if (data.text == 'Min') {
                            data.value = Array.prototype.map.call(data.value.legal_developer_access, s => s.width_min).toString();
                            var lastChar = data.value.slice(-1);
                            if (lastChar == ',') {
                                data.value = data.value.slice(0, -1);
                            }
                            //data.value = data.value.replace(/,\s*$/, "");
                        }
                    });
                } else if (r.field_name == 'Container Depth') {
                    (r.field_value || []).forEach(data => {
                        if (data.text == 'Max') {
                            data.value = Array.prototype.map.call(data.value.legal_developer_access, s => s.depth_max).toString();
                            var lastChar = data.value.slice(-1);
                            if (lastChar == ',') {
                                data.value = data.value.slice(0, -1);
                            }
                            //data.value = data.value.replace(/,\s*$/, "");
                        } else if (data.text == 'Min') {
                            data.value = Array.prototype.map.call(data.value.legal_developer_access, s => s.depth_min).toString();
                            var lastChar = data.value.slice(-1);
                            if (lastChar == ',') {
                                data.value = data.value.slice(0, -1);
                            }
                        }
                    });
                } else if (r.field_name == 'Container Diameter') {
                    (r.field_value || []).forEach(data => {
                        if (data.text == 'Max') {
                            data.value = Array.prototype.map.call(data.value.legal_developer_access, s => s.diameter_max).toString();
                            var lastChar = data.value.slice(-1);
                            if (lastChar == ',') {
                                data.value = data.value.slice(0, -1);
                            }
                        } else if (data.text == 'Min') {
                            data.value = Array.prototype.map.call(data.value.legal_developer_access, s => s.diameter_min).toString();
                            var lastChar = data.value.slice(-1);
                            if (lastChar == ',') {
                                data.value = data.value.slice(0, -1);
                            }
                        }
                    });
                } else if (r.field_name == 'Container Neck Diameter') {
                    (r.field_value || []).forEach(data => {
                        if (data.text == 'Max') {
                            data.value = Array.prototype.map.call(data.value.legal_developer_access, s => s.nack_diameter_max).toString();
                            var lastChar = data.value.slice(-1);
                            if (lastChar == ',') {
                                data.value = data.value.slice(0, -1);
                            }
                            //data.value = data.value.replace(/,\s*$/, "");
                        } else if (data.text == 'Min') {
                            data.value = Array.prototype.map.call(data.value.legal_developer_access, s => s.nack_diameter_min).toString();
                            var lastChar = data.value.slice(-1);
                            if (lastChar == ',') {
                                data.value = data.value.slice(0, -1);
                            }
                            //data.value = data.value.replace(/,\s*$/, "");
                        }
                    });
                }
            });
        }

       
        localStorage.setItem('formdata', JSON.stringify(this.data));
        this.formService.update(this.data)
        this.router.navigateByUrl('/matches');
    }, error => {
        console.log(error);
    });
    }

    toggleOptions(item, items) {
        this.blockId = item.block_id;
        this.blocks = items;
        this.blocked = item;
        this.blockTitle = item.title;
        // items.forEach(r => {
        //     r.is_selected = false;
        // });
        //item.is_selected = true;
    }

    nextLevel(blockId) {
        this.valid = null;
        this.unit = null;
       // this.container_type = this.data.productFields.find(x => x.field_name == "Container Type").title;
       if (this.data.productFields.find(x => x.field_name.toUpperCase() == "CONTAINER TYPE"))
       {
           this.container_type = this.data.productFields.find(x => x.field_name.toUpperCase() == "CONTAINER TYPE").title;  
       } 
       if (this.data.unit_id) {
            for (let i = 0; i < this.data.productFields.length; i++) {
                if (this.valid != false) {
                    if (this.data.productFields[i].block_title == "CONTAINER" && this.container_type == "Multiple Choice Dropdown") {
                        var stringToStore = JSON.stringify(this.legal_developer_access_formGroup.getRawValue());
                        ProChartStorage.setItem("test", stringToStore);
                        for (let j = 0; j < this.data.productFields[i].field_value.length; j++) {
                            if (this.data.productFields[i].field_value[j].text == 'Max') {
                                this.data.productFields[i].field_value[j].value = this.legal_developer_access_formGroup.value;

                            }
                            else if (this.data.productFields[i].field_value[j].text == 'Min') {
                                this.data.productFields[i].field_value[j].value = this.legal_developer_access_formGroup.value;

                            }
                        }
                    } else if (this.data.productFields[i].block_title != "CONTAINER" && this.container_type == "Multiple Choice Dropdown") {
                        for (let j = 0; j < this.data.productFields[i].field_value.length; j++) {
                            if (this.data.productFields[i].field_value[j].text == 'Max') {
                                this.max = this.data.productFields[i].field_value[j].value;
                            }
                            else if (this.data.productFields[i].field_value[j].text == 'Min') {
                                this.min = this.data.productFields[i].field_value[j].value;
                            }
                            if (this.min && this.max) {
                                if (parseFloat(this.min) <= parseFloat(this.max)) {
                                    this.valid = true;
                                }
                                else {
                                    this.valid = false;
                                }
                            }
                            else {
                                if (this.max) {
                                    this.valid = true;
                                }
                                else if (this.min) {
                                    this.valid = true;
                                }
                                else {
                                    this.valid = true;
                                }
                            }
                        }
                    } else {
                        for (let j = 0; j < this.data.productFields[i].field_value.length; j++) {
                            if (this.data.productFields[i].field_value[j].text == 'Max') {
                                this.max = this.data.productFields[i].field_value[j].value;
                            }
                            else if (this.data.productFields[i].field_value[j].text == 'Min') {
                                this.min = this.data.productFields[i].field_value[j].value;
                            }
                            if (this.min && this.max) {
                                if (parseFloat(this.min) <= parseFloat(this.max)) {
                                    this.valid = true;
                                }
                                else {
                                    this.valid = false;
                                }
                            }
                            else {
                                if (this.max) {
                                    this.valid = true;
                                }
                                else if (this.min) {
                                    this.valid = true;
                                }
                                else {
                                    this.valid = true;
                                }
                            }
                        }
                    }

                }
                this.min = '';
                this.max = '';
            }
        }
        else {
            this.unit = false;
        }
        for (var i = 0; i < this.blockFields.length; i++) {
            if (this.blockFields[i].block_id == blockId) {
                var next = this.blockFields[i + 1];
                this.show = false;
                this.blockId = next.block_id;
                this.blocked = next;
                next['is_selected'] = true;
                if (next.title == "REVIEW") {
                    this.show = true;
                }
            }
        }
        //if (this.valid == true) {
        //this.goNext = false
        //this.wizard.navigation.goToNextStep();
        //}
    }

    back() {
        for (var i = 0; i < this.blockFields.length; i++) {
            if (this.blockFields[i].block_id == this.blockId) {
                var next = this.blockFields[i - 1];
                var back = this.blockFields[i];
                this.blockId = next.block_id;
                this.blocked = next;
                back['is_selected'] = false;
                if (back.title == "REVIEW") {
                    this.show = true;
                } else {
                    this.show = false;
                }
                if (this.blockId == "1") {
                    this.show = false;
                }
            }
        }
    }


    ngOnDestroy() {
        if (this.modalData)
            this.modalData.close();
    }
}
var ProChartStorage = {

    getItem: function (key) {
        return localStorage.getItem(key);
    },

    setItem: function (key, value) {
        localStorage.setItem(key, value);
    },

    removeItem: function (key) {
        return localStorage.removeItem(key);
    },

    clear: function () {
        var keys = new Array();
        for (var i = 0, len = localStorage.length; i < len; i++) {
            var key = localStorage.key(i);
            if (key.indexOf("prochart") != -1 || key.indexOf("ProChart") != -1)
                keys.push(key);
        }
        for (var i = 0; i < keys.length; i++)
            localStorage.removeItem(keys[i]);
    }

}