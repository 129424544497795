import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { SignupComponent } from './pages/signup/signup.component';
// import { BuyerDashboardComponent } from './pages/buyer-dashboard/buyer-dashboard.component';
import { CmsComponent } from './pages/cms/cms.component';
import { EquipmentformModule } from './pages/equipmentform/equipmentform.module';
import { MatchedSellerModule } from './pages/matched-seller/matched-seller.module';
import { BuyerModule } from './pages/buyer/buyer.module';
import { SellerModule } from './pages/seller/seller.module';
import { SellerAuthGuard, BuyerAuthGuard, LoginGaurd } from './auth.guard';
import { PasswordModule } from './pages/password/password.module';
import { ProductListComponent } from './pages/product-list/product-list.component';
import { IntegratedLineComponent } from './pages/integrated-line/integrated-line.component';
import { HelpComponent } from './pages/help/help.component';
import {ProductViewComponent} from './pages/product-view/product-view.component';
const routes: Routes = [
  // { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent,canActivate:[LoginGaurd] },
  { path: 'signup', component: SignupComponent },
  { path: 'buyer', loadChildren: './pages/buyer/buyer.module#BuyerModule', canActivate:[BuyerAuthGuard]},
  { path: 'seller', loadChildren: './pages/seller/seller.module#SellerModule', canActivate:[SellerAuthGuard]  },
  { path: 'cms', component: CmsComponent },
  { path: 'integrated-search/:id', component: IntegratedLineComponent },
  { path: 'search/:id',loadChildren: './pages/equipmentform/equipmentform.module#EquipmentformModule' , canActivate:[BuyerAuthGuard]} ,
  { path: 'search/:id/:edit',loadChildren: './pages/equipmentform/equipmentform.module#EquipmentformModule' },
  { path: 'matches',loadChildren: './pages/matched-seller/matched-seller.module#MatchedSellerModule'  },
  {path: 'password', loadChildren: './pages/password/password.module#PasswordModule'},
  {path: 'products/:id', component: ProductListComponent  },
  {path: 'help/:id', component: HelpComponent, canActivate:[BuyerAuthGuard]} ,   
  {path: 'productsview',component: ProductViewComponent, canActivate:[BuyerAuthGuard]} , 
  { path: 'faq', component: CmsComponent },
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'}) ],
  exports: [ RouterModule ]
})



export class AppRoutingModule { }
