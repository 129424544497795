import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { JwtHelperService } from "@auth0/angular-jwt";
//import { BaseUrl } from '../url';
import { AuthService } from "./auth.service";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
// import { http } from '@angular/common'
const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};
@Injectable({
  providedIn: "root",
})
export class ApiService {
  private jwtHelper = new JwtHelperService();
  public baseUrl: string = environment.baseUrl + "client/";

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private tostr: ToastrService
  ) {}

  protected setTokens(headers) {
    if (headers.get("x-access-token")) {
      // localStorage.setItem('x-access-token', headers.get('x-access-token')).subscribe(() => { }, () => { });
      localStorage.setItem("x-access-token", headers.get("x-access-token"));
    }

    if (headers.get("refresh-token")) {
      // localStorage.setItem('refresh-token', headers.get('refresh-token')).subscribe(() => { }, () => { });
      localStorage.setItem("refresh-token", headers.get("refresh-token"));
    }
  }
  /**
   * Get request handler
   * @param token
   * @param refresh_token
   * @param url
   * @param params
   */
  protected getRequestHandler(token, refresh_token, url, params) {
    // console.log("TOKEN", token)

    const httpOptions = {
      params: new HttpParams(params),
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
        "x-access-token": token,
      }),
    };
    if (this.jwtHelper.isTokenExpired(token)) {
      console.log("TOKEN EXPIRED!");
      httpOptions.headers.append("x-refresh-token", refresh_token);
    }

    return this.http.get(url, httpOptions);
  }

  /**
   * Refresh token fetcher (link  --> getRequestHandler)
   * @param url
   * @param data
   * @param token
   */
  protected makeGetRequest(url, params, token) {
    return this.getRequestHandler(
      token,
      localStorage.getItem("x-refresh-token"),
      url,
      params
    );
  }

  /**
   * Consolidated get request
   * @param url
   * @param params
   */
  public _get(url: string, params: any): Observable<any> {
    url = this.baseUrl + url;
    console.log("get url-+-++-+-+-", url);

    // localStorage.getItem('x-access-token').subscribe(res=>(console.log("INS",res)))
    return this.makeGetRequest(
      url,
      params,
      localStorage.getItem("x-access-token")
    ).pipe(
      tap((response) => {
        if (
          response["statusCode"] == "TokenExpired" ||
          response["statusCode"] == "TokenInvalid"
        ) {
          localStorage.clear();
        }
        return response;
      }),
      catchError(
        this.handleError("GET" + url, {
          status: false,
          message: "Network Error!",
        })
      )
    );
  }

  public get(url:string ,params?:any):Observable<any>{
    url = this.baseUrl + url;
    return this.http.get(url)
  }

  /**
   * Post handler
   * @param token
   * @param refresh_token
   * @param url
   * @param data
   */
  protected postRequestHandler(token, refresh_token, url, data) {
    // console.log("lolo",token, refresh_token)
    const httpOptions2 = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };
    if (refresh_token && token) {
      httpOptions2.headers = httpOptions2.headers.append(
        "x-access-token",
        token
      );
      if (this.jwtHelper.isTokenExpired(token))
        httpOptions2.headers = httpOptions2.headers.append(
          "x-refresh-token",
          refresh_token
        );
      // if (token)
    }
    //  console.log("INYYAE",httpOptions2)
    return this.http.post<any>(url, data, httpOptions2);
  }
  /**
   * Refresh token fetcher (link  --> postRequestHandler)
   * @param url
   * @param data
   * @param token
   */
  protected makePostRequest(url, data, token) {
    return this.postRequestHandler(
      token,
      localStorage.getItem("x-refresh-token"),
      url,
      data
    );
  }

  /**
   * Consolidate for post
   * @param url
   * @param data
   */
  public _post(url: string, data: any): Observable<any> {
    url = this.baseUrl + url;
    return this.makePostRequest(
      url,
      data,
      localStorage.getItem("x-access-token")
    ).pipe(
      tap((response) => {
        if (
          response["statusCode"] == "TokenExpired" ||
          response["statusCode"] == "TokenInvalid"
        ) {
          this.auth.logout(2);
        }
        return response;
      }),
      catchError(
        this.handleError("POST" + url, {
          status: false,
          message: "Network Error!!",
        })
      )
    );
  }

  postDataApi(url: string, data: any): Observable<any> {
    let apiUrl = this.baseUrl;
    return this.http
      .post<any>(apiUrl + url, data, { headers: httpOptions.headers })
      .pipe(
        tap((heroes) => console.log(`register`, heroes)),
        catchError(
          this.handleError("authenticate", {
            status: false,
            message: "Network Error!",
          })
        )
      );
  }

  _put(url: string, data: any): Observable<any> {
    url = this.baseUrl + url;
    const token = localStorage.getItem("x-access-token");
    const headers = {
      headers: new HttpHeaders({ "x-access-token": token }),
    };
    headers.headers.append("content-type", "application/json");
    headers.headers.append("x-access-token", token);
    return this.http.put(url, data, headers);
  }

  /**
   * Refresh token fetcher (link  --> postFileHandler)
   * @param url
   * @param data
   * @param token
   */
  protected makeFilePost(url, data, token) {
    return this.postFileHandler(
      token,
      localStorage.getItem("x-refresh-token"),
      url,
      data
    );
  }
  /**
   * Post file handler
   * @param token
   * @param refresh_token
   * @param url
   * @param data
   */
  protected postFileHandler(token, refresh_token, url, formData) {
    // console.log("lolo",token, refresh_token)
    const httpOptions12 = {
      headers: new HttpHeaders(),
    };
    if (refresh_token && token) {
      httpOptions12.headers = httpOptions12.headers.append(
        "x-access-token",
        token
      );
      if (this.jwtHelper.isTokenExpired(token))
        httpOptions12.headers = httpOptions12.headers.append(
          "x-refresh-token",
          refresh_token
        );
      // if (token)
    }
    //  console.log("INYYAE",httpOptions2)
    return this.http.post<any>(url, formData, httpOptions12);
  }
  /**
   * Consolidate for post
   * @param url
   * @param data
   */
  public _postWithFile(url: string, formData: any): Observable<any> {
    url = this.baseUrl + url;
    return this.makeFilePost(
      url,
      formData,
      localStorage.getItem("x-access-token")
    ).pipe(
      tap((response) => {
        if (
          response["statusCode"] == "TokenExpired" ||
          response["statusCode"] == "TokenInvalid"
        ) {
          this.auth.logout(2);
        }
        return response;
      }),
      catchError(
        this.handleError("POST " + url, {
          status: false,
          message: "Network Error!",
        })
      )
    );
  }
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      console.log("API ERROR!");
      this.tostr.error("Unexpected Error!");

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  public applyCoupon(code: string) {
    console.log("applied code-++-+-+---", code);

    const token = localStorage.getItem("x-access-token");
    const httpOptions = {
      headers: new HttpHeaders(),
    };
    httpOptions.headers = httpOptions.headers.append("x-access-token", token);
    return this.http.post(
      this.baseUrl + "coupon_validator",
      { couponCode: code },
      httpOptions
    );
  }
}
