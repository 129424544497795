import { Component, OnInit, ViewChild, ElementRef,ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PassportService } from '../../shared/services/passport.service';
import { PasswordValidation } from '../../shared/directives/validation.directive';
import { AuthService } from '../../shared/services/auth.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss',
                '../../../assets/css/login.css'
         ],
                
})
export class LoginComponent implements OnInit {
  public submitted = false;
  public loginForm: FormGroup;
  public formError = '';
  public formSucess = '';
  public form: any = 'valid';

  constructor(private router: Router, private passService: PassportService, private token: AuthService, private toastr: ToastrService) {

  }
  ngOnInit(): void {
    this.loginForm = new FormGroup({
      'email': new FormControl('', {
        validators: [Validators.required,Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)],
        updateOn: 'change'
      }),
      'password': new FormControl('',{
        validators: [Validators.required],
        updateOn: 'change'
      }),
    })
  }
 
  isFieldInvalid(field: string) {
    if (this.submitted && this.loginForm.get(field).invalid && (this.loginForm.get(field).dirty || this.loginForm.get(field).touched))
   return 'error'  
  }


  auth() {
    this.submitted = true;
    if (this.loginForm.valid) {
      this.passService.authenticate(this.loginForm.value)
        .subscribe((result) => {
          console.log("LOGIN result:-",result)
          if (result.body.status) {
            this.token.login(result.headers,result.body);
            console.log("Logged IN",result.headers,result.body);
            
            this.loginForm.reset();
            this.submitted = false
          } else {
            this.toastr.warning(result.body.message);
          }
          // this.common.hideLoader();
        });
    } else {
      PasswordValidation.validateAllFormFields(this.loginForm);
    }
  }

  gotoPage(name: string) {
    console.log(name)
    this.router.navigate(['/cms'], { queryParams: { name: name } });
  }

}
