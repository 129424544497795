import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams} from '@angular/common/http';
import { BehaviorSubject, Observable ,  of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
//import { BaseUrl } from '../url';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};




@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  
  
  //private baseUrl = BaseUrl;  // URL to web api
  public baseUrl: string = environment.baseUrl;
  public search=new BehaviorSubject<string>("");
  constructor(
    private http: HttpClient,private tostr: ToastrService
    ) { }
/**
 * Get the product list for landing page
 */
getProducts(): Observable<any> {
  return this.http.get(this.baseUrl+'users/productsList',httpOptions)
    .pipe(
      tap(heroes => console.log(`Get Products`)),
      catchError(this.handleError('getProducts', { status: false, message: 'Network Error!' }))
    );
}
getViewProducts(): Observable<any> {
  return this.http.get(this.baseUrl+'users/getProductsListWithLocation',httpOptions)
    .pipe(
      tap(heroes => console.log(`Get Products`)),
      catchError(this.handleError('getProducts', { status: false, message: 'Network Error!' }))
    );
}
/**
 * Get the product fields of individual w.r.t the product id
 * @param id 
 */
getProductsFields1(id): Observable<any> {
  let params = {'product_id': id};
  return this.http.post(this.baseUrl+'users/productDetails', params)
    .pipe(
      tap(heroes => console.log(`Get Products Fields`)),
      catchError(this.handleError('getProductsFields', { status: false, message: 'Network Error!' }))
    );
}
/**
 * Get the product fields of individual w.r.t the product id
 * @param id 
 */
getIntegratedFields1(id): Observable<any> {
  let params = {'product_id': id};
  return this.http.post(this.baseUrl+'users/productDetails', params)
    .pipe(
      tap(heroes => console.log(`Get Products Fields`)),
      catchError(this.handleError('getProductsFields', { status: false, message: 'Network Error!' }))
    );
}


/**
 * Get the product fields of integrated w.r.t the product id
 * @param id 
 */
getProductsFields2(id): Observable<any> {
  let params = {'product_id': id};
  return this.http.post(this.baseUrl+'users/product_details', params)
    .pipe(
      tap(heroes => console.log(`Get Products Fields`)),
      catchError(this.handleError('getProductsFields', { status: false, message: 'Network Error!' }))
    );
}


editProductData(id,master_product_id,model): Observable<any> {
  let params = {'product_id': id,'master_product_id':master_product_id,model};
  console.log("params-+++-+-+-+-+",params);
  const token=localStorage.getItem('x-access-token')
  const headers={
    headers: new HttpHeaders({ 'x-access-token': token })
  };
  headers.headers.append('content-type', 'application/json')
  headers.headers.append('x-access-token', token)
  return this.http.post(this.baseUrl+'client/edit_seller_product', params,headers)
    .pipe(
      tap(heroes => console.log(`Get Products Fields`)),
      catchError(this.handleError('getProductsFields', { status: false, message: 'Network Error!' }))
    );
}


/**
 * Get the product fields of integrated w.r.t the product id
 * @param id 
 */
getExtraFields(id): Observable<any> {
  let params = {'product_id': id};
  return this.http.post(this.baseUrl+'users/individul_product_details', params)
    .pipe(
      tap(heroes => console.log(`Get Products Fields`)),
      catchError(this.handleError('getProductsFields', { status: false, message: 'Network Error!' }))
    );
}
/**
 * send the filled data for search
 * @param data
 */
setProductsData(data): Observable<any> {
  let params = {'data': data};
  return this.http
  .post(this.baseUrl+'client/product_search', params)
    .pipe(
      tap(heroes => console.log(`Send Products data`)),
      catchError(this.handleError('setProductsData', { status: false, message: 'Network Error!' }))
    );
}
/**
 * send the filled data for search
 * @param data
 */
setIntegratedProductsData(data): Observable<any> {
  let params = {'data': data};
  return this.http
  .post(this.baseUrl+'users/searchIntegratedProducts', params)
    .pipe(
      tap(heroes => console.log(`Send Integrated Products data`)),
      catchError(this.handleError('setIntegratedProductsData', { status: false, message: 'Network Error!' }))
    );
}
/**
 * Upload the file
 * @param fileToUpload 
 */
postFile(fileToUpload: File): Observable<any> {

 //const endpoint = this.baseUrl+'client/'+'users/fileUpload';
 const endpoint = this.baseUrl+'client/upload';
  //const endpoint= 'http://localhost:3000/client/upload';
  const formData: FormData = new FormData();
  formData.append('file', fileToUpload);
  formData.append('fileName', fileToUpload);
  return this.http
    .post(endpoint, formData)
    .pipe(
      tap(heroes => console.log(`Doc upload`)),
      catchError(this.handleError('uploadfile', { status: false, message: 'Network Error!' })))
}
/**
/**
 * Upload the seller's product image file
 * @param imageFileToUpload 
 */
postImageFile(imageFileToUpload: File): Observable<any> {

 //const endpoint = this.baseUrl+'client/'+'users/fileUpload';
 const endpoint = this.baseUrl+'client/image_upload';
  //const endpoint= 'http://localhost:3000/client/upload';
  const formData: FormData = new FormData();
  formData.append('file', imageFileToUpload);
  formData.append('fileName', imageFileToUpload);
  return this.http
    .post(endpoint, formData)
    .pipe(
      tap(heroes => console.log(`Doc upload`)),
      catchError(this.handleError('uploadfile', { status: false, message: 'Network Error!' })))
}
/**
 * 
 */
/**
 * Upload the file
 * @param fileToUpload 
 */
 postFileBuyer(fileToUpload: File): Observable<any> {

  //const endpoint = this.baseUrl+'client/'+'users/fileUpload';
  const endpoint = this.baseUrl+'client/upload_bfile';
   //const endpoint= 'http://localhost:3000/client/upload';
   const formData: FormData = new FormData();
   formData.append('file', fileToUpload);
   formData.append('fileName', fileToUpload);
   return this.http
     .post(endpoint, formData)
     .pipe(
       tap(heroes => console.log(`Doc upload`)),
       catchError(this.handleError('uploadfile', { status: false, message: 'Network Error!' })))
 }
 /**
  * 
  */
getIndividualProducts(): Observable<any> {
  return this.http.get(this.baseUrl+'product_list_individual',httpOptions)
    .pipe(
      tap(heroes => console.log(`Get Seller Individual Products`)),
      catchError(this.handleError('getProducts', { status: false, message: 'Network Error!' }))
    );
}
/**
 * 
 */
getIntegratedProducts(): Observable<any> {
  return this.http.get(this.baseUrl+'product_list_integrated',httpOptions)
    .pipe(
      tap(heroes => console.log(`Get Seller Integaratd Products`)),
      catchError(this.handleError('getProducts', { status: false, message: 'Network Error!' }))
    );
}
/**
 * 
 * @param data 
 */
setIndividualProduct(data): Observable<any> {
  let params = {'data': data};
  return this.http
  .post(this.baseUrl+'add_product_info', params)
    .pipe(
      tap(heroes => console.log(`Send Integrated Products data`)),
      catchError(this.handleError('setIntegratedProductsData', { status: false, message: 'Network Error!' }))
    );
}
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      console.log("API ERROR!!!")
      this.tostr.error('Unexpected Error!')
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }



}
