import { Component, OnInit } from "@angular/core";
import { NgbTabsetConfig } from "@ng-bootstrap/ng-bootstrap";
import { ReactiveFormsModule } from "@angular/forms";
import { FormsModule } from "@angular/forms";
import { ProductsService } from "src/app/shared/services/products.service";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { ApiService } from "src/app/shared/services/api.service";
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
  providers: [NgbTabsetConfig],
})
export class HomeComponent implements OnInit {
  slideConfig = {
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: true,
    infinite: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  currentJustify = "start";
  public products: Array<any> = [];
  public tab1: any = [];
  public tab2: any = [];
  userData = {};
  locationBasedProducts=[]
  public revproducts: Array<any> = [];
  constructor(
    config: NgbTabsetConfig,
    private apiservice: ApiService,
    private productservice: ProductsService
  ) {
    // customize default values of tabsets used by this component tree
    config.justify = "start";
    config.type = "pills";
  }

  ngOnInit() {
    window.scrollTo(0, 0);

    let totalProducts;

    localStorage.removeItem("formdata");
    this.userData = JSON.parse(localStorage.getItem("user-data"));
    this.productservice.getProducts().subscribe((result) => {
      this.products = result.data;

      //totalProducts = this.products.length;
      //this.revproducts=result.data.reverse();
      this.tab1 = this.products.filter(
        (y) => y.product_type == "Integrated Machine"
      );
      this.tab2 = this.products.filter(
        (y) => y.product_type == "Individual Machine"
      );

      //temporary to show different products in most view category
      this.revproducts = [];
      for (let i = this.products.length - 1; i >= 0; i--) {
        this.revproducts.push(this.products[i]);
      }
      //-----------------------------------------
    });

       this.apiservice.get('locationBasedProductList',{}).subscribe((res)=>{
        console.log('location based products---++--+',res)
        this.locationBasedProducts=res.data
      })



  }




}
