import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'

})
export class SearchPipe implements PipeTransform {
//  transform(vList: any[], vfilter: any[], key: any[]): any {
//    if (!vList || !Object.keys(vfilter).length) {
//      return vList;
//    } 
//    return vList.filter(item => {
//            return Object.keys(vfilter)
//              .filter(_ => vfilter.hasOwnProperty(_))
//              .every(key => {
//                if(!item[key]) return true; // matches undefined value
//                const arrayValues = vfilter[key] as any[];
//                return arrayValues.some(_ => _ === item[key]);
//            });
//        });

//  }


  transform(list: any[], value: any[], key: any[]): any {
     
     
    value.forEach((name, index) => {
     if (name) {
       list = list.filter((item) => {
          return (item[key[index]]
            .toString()
           .toLowerCase()
           .indexOf(name.toString().toLowerCase()) !== -1)
       });
      }
    });
    return list;
  }
}
