import { Component, OnInit } from '@angular/core';
import { OthersService } from '../../shared/services/others.service';
import { Router, NavigationEnd } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public submitted = false;
  public emailForm: FormGroup;
  public year = (new Date()).getFullYear();

  constructor(private router: Router, private otherservice: OthersService) { }

  ngOnInit() {
    this.emailForm = new FormGroup({
      'email': new FormControl('', {
        validators: [Validators.required, Validators.email],
        updateOn: 'change'
      })
    })
  }

  isFieldInvalid(field: string) {
    if (this.submitted && this.emailForm.get(field).invalid && (this.emailForm.get(field).dirty || this.emailForm.get(field).touched))
   return 'error'  
  }

  onSubmit() {
    this.submitted = true;
    console.log("FOOTER",this.emailForm.value)
    if (this.emailForm.valid) {
    this.otherservice.newsletter(this.emailForm.value.email)
      .subscribe((result) => {
        if (result.status) {
          console.log(result)
          this.emailForm.reset;
          this.submitted =false;
        }
        else {
          
        }
      });
    }
  }

  gotoPage(name: string) {
    console.log(name)
    this.router.navigate(['/cms'], { queryParams: { name: name } });
  }

}
