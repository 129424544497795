import { Component, OnInit } from '@angular/core';
import {NgbTabsetConfig} from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/shared/services/api.service';

import { ProductsService } from 'src/app/shared/services/products.service';
import { FormControl,FormsModule,ReactiveFormsModule } from '@angular/forms';


@Component({
  selector: 'app-product-view',
  templateUrl: './product-view.component.html',
  styleUrls: ['./product-view.component.scss']
})
export class ProductViewComponent implements OnInit {
  selectedItems: any;
  showLocation: boolean = false
  titlep = new FormControl('');
  sic = new FormControl(''); 
  eqp = new FormControl(''); 
  eqptitle= new FormControl(''); 
  eqptitleid= new FormControl(''); 
  multiDropdownSettings:any;
  machinetype= new FormControl(''); 
  typeequipment= new FormControl(''); 
  typeequipmentchk= new FormControl(''); 
  locationchk= new FormControl(''); 
  locationtxt= new FormControl(''); 
 
  

  public filterCategory:Array<any> = [];
  public filterSic:Array<any> = [];
  public filterTypeEquipment:Array<any> = [];
  public filterLocation:Array<any> = [];
  public filterEqp:Array<any> = [];
  public  searchTerm:string='';
  public  searchTypeEquipment:string='';
  public  searchSic:string='';

    searchKey:string='';
    currentJustify = 'start';
    public products:Array<any> = [];
    public tab1:any =[];
    public tab2:any =[]
    userData = {}
    locations=[]
    title;
    id;
    public uniqsic:any =[];
    public machineArr: Array<any> = [
      {name: 'Individual Machine', value: 'Individual Machine'},
      {name: 'Integrated Machine', value: 'Integrated Machine'}
      
    ];
    checklist:any;
    checkedList:any;
    checkedLocationList:any;

   
    constructor(config: NgbTabsetConfig,private productservice: ProductsService,private route: ActivatedRoute, private spinner: NgxSpinnerService, private apiservice: ApiService) {
      // customize default values of tabsets used by this component tree
      config.justify = 'start';
      config.type = 'pills';
    }
  
    ngOnInit() {
      window.scrollTo(0, 0);
      this.iniDropDownSetting();
      localStorage.removeItem('formdata')
      this.userData = JSON.parse(localStorage.getItem("user-data"));
      
        
         this.machinetype.valueChanges.subscribe(value=> {
          console.log('machine type has changed', value);
        })

        this.typeequipmentchk.valueChanges.subscribe(value=> {
          console.log('type equipment checkbox has clicked', value);
        })

        this.locationchk.valueChanges.subscribe(value=> {
          console.log('location  checkbox has clicked', value);
        })

      this.productservice.getViewProducts()
      .subscribe((result) => {
        console.log('all products-+-+-+-+-+-+',result)
    result.data.forEach(element => {
          element.isSelected=true
        });
       this.products = result.data;
       this.locations=result.locations
       this.filterCategory=result.data;

       //   this.filterSic=result.data;
  
      // this.filterSic=this.products.from(new Set(this.products));
  
      //this.filterSic=result.data.filter((sic_code, i ) => result.data.indexOf(sic_code) === i);

      this.filterSic = result.data.filter((value, index, self) => self.map(x => x.sic_code).indexOf(value.sic_code) == index)
      this.filterEqp = result.data.filter((value, index, self) => self.map(x => x.equipment_title).indexOf(value.equipment_title) == index)
      this.filterTypeEquipment = result.data.filter((value, index, self) => self.map(x => x.title).indexOf(value.title) == index)
      this.filterLocation = result.data.filter((value, index, self) => self.map(x => x.location).indexOf(value.location) == index)
      // console.log('filterTypeEquipment---+-+-++---',this.filterTypeEquipment)

      // this.products.filter((item,index)=>this.products.indexOf(item.sic_code)!==index);
       this.tab1 = this.products.filter(y=>y.product_type =='Integrated Machine')
       this.tab2 = this.products.filter(y=>y.product_type =='Individual Machine')
       console.log(this.products);
       
      })

    this.route.params.subscribe((params) => {
      console.log(params,"params");
          this.id = params.id
          if(this.id==1){
            this.title = "Food"
          }
          else if(this.id==2){
            this.title = "Beverage"
          }
          else if(this.id==3){
            this.title = "Pharma/Nutraceutical"
          }
          else if(this.id==4){
            this.title = "Cosmetic"
          }
          else if(this.id==5){
            this.title = "Chemical"
          }
          else if(this.id==5){
            this.title = "Machinery"
          }
          else if(this.id==6){
            this.title = "Others"
          }
    
          this. getIndividual('',this.id)
                });

  this.productservice.search.subscribe((val:any)=>{
    this.searchKey=val;
  })        
  
  
  

  }
  
  iniDropDownSetting() {
    this.multiDropdownSettings = {
        singleSelection: false,
        idField: 'equipment_category_id',
        textField: 'equipment_title',
        selectAllText: 'Select All',
        unSelectAllText: 'Unselect All',
        searchPlaceholderText: 'Search',
        allowSearchFilter: true,
        itemsShowLimit: 3
    };
}
 tempArray:any=[];
 newArray:any=[];
onItemSelect(items: any,itm:any) {

  // this.filterCategory=this.products
  //   .filter((a:any)=>{
  //      if(a.equipment_category_id==items.equipment_category_id||items.equipment_category_id == ''){
  //        return a;
  //      }
       
  //    })
 
  //  }
 
 
  //this.filterCategory= this.filterCategory.filter(i => itm.equipment_category_id.includes(i));
  //const categories2 = this.filterCategory.filter((item) => itm.equipment_category_id.every((key) => item[key]));
  //let filteredServices = this.filterCategory.filter((element) => element.equipment_category_id.some((itm) => itm.equipment_category_id === element.equipment_category_id)); 
  this.filterCategory= this.products.filter(f => itm.some(item => item.equipment_category_id === f.equipment_category_id));
  console.log(itm);
  /*  if(items){
     this.tempArray=this.products.filter((e:any)=>e.equipment_category_id==items.equipment_category_id);
     this.filterCategory=[];
     this.newArray.push(this.tempArray);
  
    for (let i=0;i<this.newArray.length;i++){
    var firstArray=this.newArray[i];
    for(let i=0;i<firstArray.length;i++){
     var obj=firstArray[i];
      this.filterCategory.push(obj);
     }
    }
   }
 else
 {
   this.filterCategory=this.products; 
 } */
}

onSelectAll(items: any,itm:any) {
  console.log(items);
  this.filterCategory= this.products;
}
onItemDeSelect(items: any,itm:any) {
  this.filterCategory= this.products.filter(f => itm.some(item => item.equipment_category_id === f.equipment_category_id));
  //   this.tempArray=this.products.filter((e:any)=>e.equipment_category_id != items.equipment_category_id);
  //   this.newArray=[];
  //   this.filterCategory=[];
  //   this.newArray.push(this.tempArray);
  
  //  for(let i=0;i< this.newArray.length;i++){
  //    var firstArray=this.newArray[i];
  //    for(let i=0;i<firstArray.length;i++){
  //      var obj=firstArray[i];
  //      this.filterCategory.push(obj);
  //    }
  //   }
  
  }
 
  openLocation() {
   
    this.showLocation = !this.showLocation
    console.log("open Location called-+-+---++-",this.showLocation)
  }
  selectLocation(location: any) {
    
    this.locationtxt.patchValue(location) 
    this.showLocation = false
    console.log("Selected Location -+-+---++-",this.locationtxt.value)
  }
  
  onFilterChange(event:any){
    console.log('test:'+event);
    //console.log(this.products);
   
  }
  

  getIndividual(key,id){
    console.log(key,id,"something");
    this.apiservice._post('all_individual_products',{search_key:key,category_id:id}).subscribe((result)=>{
      console.log(result,"result");
      if(result.status){
        if(result.data){
          this.products = result.data
         // this.imgUrl = result.image_url;
         // this.tagImg = result.category_image
        }
            console.log("IND PRODUCTS", this.products)
            this.spinner.hide();
      }
          })
  }
  
  search(event:any){
    this.searchTerm=(event.target as HTMLInputElement).value;
   
    console.log(this.searchTerm);
    this.productservice.search.next(this.searchTerm);
  }
  filter(category:string){
    this.filterCategory=this.products
    .filter((a:any)=>{
      if(a.equipment_category_id==category||category==''){
        return a;
      }
      
    })

  }

  searchsic(event:any){
    this.searchSic=(event.target as HTMLInputElement).value;
    console.log(this.searchSic);
    this.sic.setValue((event.target as HTMLInputElement).value);
  //  this.productservice.search.next(this.searchSic);
  }

 
  //var unique = myArray.filter((v, i, a) => a.indexOf(v) === i);
 // array.filter((item, index) => array.indexOf(item) !== index);
 
  

  filtersic(category:string){
   this.filterSic=this.products
   .filter((a:any)=>{
      if(a.sic_code==category||category==''){
        return a;
      }
      
    })

  }

  searchtypeequipmentx(event:any){
    this.searchTypeEquipment=(event.target as HTMLInputElement).value;
    console.log(this.searchTypeEquipment);
    this.title.setValue((event.target as HTMLInputElement).value);
  //  this.productservice.search.next(this.searchSic);
  }
  filtertypeequipment(title:string){
    this.filterTypeEquipment=this.products
    .filter((a:any)=>{
       if(a.title==title||title==''){
         return a;
       }  
     })
    }


  onMachineSelect(item: any, itm:any) {
    this.checkedList = [];
    for (var i = 0; i < this.machineArr.length; i++) {
      if(this.machineArr[i].isSelected)
      this.checkedList.push(this.machineArr[i]);
    }
   // this.checkedList = JSON.stringify(this.checkedList); 
   //this.filterCategory=this.products.filter(f => ! this.checkedList.includes(f));

   this.filterCategory = this.products.filter(item1 => 
    !!this.checkedList.find(item2 => item1.product_type === item2.value)
   );
  }

  onTypeEquipmentSelect(item: any, itm:any) {
    console.log('product selected+--+--++-',item,itm)
    this.filterCategory=this.products
    this.checkedList = [];
    for (var i = 0; i < this.filterTypeEquipment.length; i++) {
      if(this.filterTypeEquipment[i].isSelected)
      this.checkedList.push(this.filterTypeEquipment[i]);
    }
   // this.checkedList = JSON.stringify(this.checkedList); 
  // this.filterCategory=this.products.filter(f =>  this.checkedList.includes(f));

   this.filterCategory = this.products.filter(item1 => 
    !!this.checkedList.find(item2 => item1.title === item2.title)
   );
  }
  
  filterlocationX(location:string){
    this.filterLocation=this.products
    .filter((a:any)=>{
       if(a.location==location || location=='' ){
         return a;
       }  
     })
    }

  onLocationSelect(item: any, itm:any) {
    this.checkedLocationList = [];
    for (var i = 0; i < this.filterLocation.length; i++) {
      if(this.filterLocation[i].isLocSelected)
      this.checkedLocationList.push(this.filterLocation[i]);
    }
   // this.checkedList = JSON.stringify(this.checkedList); 
   //this.filterCategory=this.products.filter(f =>  this.checkedLocationList.includes(f));


   this.filterCategory = this.products.filter(item1 => 
    !!this.checkedLocationList.find(item2 => item1.location === item2.location)
);
  }
  


  


  }
  