import { Component, OnInit } from '@angular/core';
import { McBreadcrumbsService } from 'ngx-breadcrumbs';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
public breadcrumbs : any = [];
  constructor(private crumb: McBreadcrumbsService) { }

  ngOnInit() {
  this.crumb.crumbs$.subscribe((events) => {
      // console.log("events", events);
      this.breadcrumbs = events
    })
  
  }

}
