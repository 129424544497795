import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';
//import { BaseUrl } from '../url';
import { Observable ,  of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PassportService } from './passport.service';
import { User } from 'src/app/models/users.model';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class OthersService {
  public user: User = {};
 // private baseUrl = BaseUrl;  // URL to web api
  public baseUrl: string = environment.baseUrl
  constructor(
    private http: HttpClient,private passService: PassportService,) { }

    newsletter(email: String): Observable<any> {
      console.log("EMAIL::",email)
        return this.http.post<any>(this.baseUrl +'client/'+'newsletter',{email: email}, httpOptions)
          .pipe(
            tap(heroes => console.log(`newsletter email`)),
            catchError(this.handleError('newsletter', { status: false, message: 'Network Error!'}))
          );
      }

      cmsPage(name): Observable<any> {

        // Setup log namespace query parameter
        let params = new HttpParams().set('name', name);
    
        return this.http.get(this.baseUrl +'client/page', { params: params })
        .pipe(
          tap(heroes => console.log(`Cms sucessfully fetched`)),
          catchError(this.handleError('newsletter', { status: false, message: 'Network Error!'}))
        );
    }

        /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }


  register(data) {
    this.passService.register(data).subscribe(r => {
      this.user = r.data;
      console.log(this.user,"user");
    }),error => {
      console.log(error)
      //   this.cs.loading(false);
      //   Swal.fire({
      //     icon: 'error',
      //     title: 'Oops...',
      //     text: error.error.message,
      //     showConfirmButton: false,
      //     timer: 1500
      //   })
      // });
  }
}
}
