import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../app/shared/services/auth.service';
import {Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';


@Injectable({providedIn: 'root'})
export class SellerAuthGuard implements CanActivate {

  constructor(private auth: AuthService,private myRoute: Router,public toastr: ToastrService){
      // console.log("IN AUTH");
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if(this.auth.isSellerLoggednIn()){
        // console.log("AUTHORIZED");
        return true;
      }else{
        if(this.auth.isLoggednIn())
        {this.toastr.error('Please Login as Seller', 'Not Authorized!');}
        // toast("Not authorized",4000)
        else
        this.myRoute.navigate(["login"]);
        return false;
      }
  }
}

@Injectable({ providedIn: 'root' }) 
export class BuyerAuthGuard implements CanActivate {

    constructor(private auth: AuthService,private myRoute: Router, public toastr: ToastrService){
        // console.log("IN AUTH");
    }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if(this.auth.isBuyerLoggednIn()){
        // console.log("AUTHORIZED");
        return true;
      }else{
        // console.log("NOT AUTHORIZED")
        if(this.auth.isLoggednIn())
        // {}
        this.toastr.error('Please Login as Buyer', 'Not Authorized!');
        else
        this.myRoute.navigate(["login"]);
        return false;
      }
  }
}

@Injectable({providedIn: 'root'})
export class LoginGaurd implements CanActivate {

  constructor(private auth: AuthService,private myRoute: Router,public toastr: ToastrService){
      console.log("IN AUTH");
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if(this.auth.isLoggednIn()){
        if(this.auth.isSellerLoggednIn())
        {
          this.myRoute.navigate(["seller"]);
        }
        else if(this.auth.isBuyerLoggednIn()){
          this.myRoute.navigate(["buyer"]);
       
        }
        return false;
      }else{
   return true
  }
}
}
