import { Component, OnInit } from '@angular/core';
import {NgbTabsetConfig} from '@ng-bootstrap/ng-bootstrap';
import {ReactiveFormsModule} from '@angular/forms';
import {FormsModule} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit {
  title
 id
 searchKey
 imgUrl
 tagImg
 products
 userData:any = {}
  currentJustify = 'start';
  constructor(config: NgbTabsetConfig,private route: ActivatedRoute, private spinner: NgxSpinnerService, private apiservice: ApiService) {
    // customize default values of tabsets used by this component tree
    config.justify = 'start';
    config.type = 'pills';
  }

  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem("user-data"));
    this.route.params.subscribe((params) => {
  console.log(params,"params");
      this.id = params.id
      if(this.id==1){
        this.title = "Food"
      }
      else if(this.id==2){
        this.title = "Beverage"
      }
      else if(this.id==3){
        this.title = "Pharma/Nutraceutical"
      }
      else if(this.id==4){
        this.title = "Cosmetic"
      }
      else if(this.id==5){
        this.title = "Chemical"
      }
      else if(this.id==5){
        this.title = "Machinery"
      }
      else if(this.id==6){
        this.title = "Others"
      }

      this. getIndividual('',this.id)
            })
           
  }


  getIndividual(key,id){
    console.log(key,id,"something");
    this.apiservice._post('all_individual_products',{search_key:key,category_id:id}).subscribe((result)=>{
      console.log(result,"result");
      if(result.status){
        if(result.data){
          this.products = result.data
          this.imgUrl = result.image_url;
          this.tagImg = result.category_image
        }
            console.log("IND PRODUCTS", this.products)
            this.spinner.hide();
      }
          })
  }
  search(){
    console.log("KEY", this.searchKey)
    this.getIndividual(this.searchKey,this.id)
  }

}




