import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalstorageService {
  private localstorage = new Subject<any>();
  constructor() {
    // this.localstorage = JSON.parse(localStorage.getItem("user-data"))  
   }

  public setlocal() {
    this.localstorage.next(); // this will make sure to tell every subscriber about the changed
  }
 public getlocal(): Observable<any> {
    return this.localstorage.asObservable();
}
}
