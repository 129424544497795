import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormdataService {
  public data: any = {};
  public nextfield: any = {};
  public selectedBlock: any;
  public extraBlock: string;
  constructor(){
 
    this.data = {
        id: 18,
        title: "Test123",
      product_type: "Integrated Machine",
        unit_id: "",
        blocks: [
            {
                product_id: 18,
                block_id: 66,
                title: "Default",
                sub_title: "",
                image: "http://newagesme.com/packmatch/admin/uploads/blocks/block.png"
            },
            {
                product_id: 18,
               block_id: 67,
                title: "PROCESS",
                sub_title: "Test2",
                image: "http://newagesme.com/packmatch/admin/uploads/blocks/block.png"
            }
        ],
        childBlocks: [
            {
               product_id: 18,
            //    block_id: 67,
                title: "PROCESS",
                sub_title: "ALREADY PRESENT",
                image: "http://newagesme.com/packmatch/admin/uploads/blocks/block.png"
            },
            {
                product_id: 20,
                // block_id: 68,
                title: "PROCESS",
               sub_title: "NEWQLY ADDED",
               image: "http://newagesme.com/packmatch/admin/uploads/blocks/block.png"
            },
            {
                product_id: 19,
                // block_id: 68,
                title: "PROCESS",
               sub_title: "NEWQLY 13e ADDED",
               image: "http://newagesme.com/packmatch/admin/uploads/blocks/block.png"
            }
        ],
        productFields: [
            {
               field_id: 437,
               product_id: 18,
               block_id: 66,
                field_name: "test",
               field_type: "textbox",
                input_name: "number",
                title: "Number Field(Inches/mm)",
                values: [],
                field_value: [
                    {
                        text: "",
                        value: ""
                    }
                ]
            },    
        ],
        integratedProductFields:[
                {
                    product_id: 18,
                    block_id: 66,
                    title: "Default",
                    sub_title: "",
                    fields:[
                        {
                            field_id: 437,
                            product_id: 18,
                            block_id: 66,
                             field_name: "test",
                            field_type: "textbox",
                             input_name: "number",
                             title: "Number Field(Inches/mm)",
                             values: [],
                             field_value: [
                                 {
                                     text: "",
                                     value: ""
                                 }
                             ]
                         },
                         {
                            field_id: 437,
                            product_id: 18,
                            block_id: 66,
                             field_name: "test",
                            field_type: "textbox",
                             input_name: "number",
                             title: "Number Field(Inches/mm)",
                             values: [],
                             field_value: [
                                 {
                                     text: "",
                                     value: ""
                                 }
                             ]
                         }
                    ]
                },
                {
                    product_id: 18,
                   block_id: 67,
                    title: "PROCESS",
                    sub_title: "Test2",
                    fields:[
                        {
                            field_id: 437,
                            product_id: 18,
                            block_id: 66,
                             field_name: "test",
                            field_type: "textbox",
                             input_name: "number",
                             title: "Number Field(Inches/mm)",
                             values: [],
                             field_value: [
                                 {
                                     text: "",
                                     value: ""
                                 }
                             ]
                         },
                         {
                            field_id: 437,
                            product_id: 18,
                            block_id: 66,
                             field_name: "test",
                            field_type: "textbox",
                             input_name: "number",
                             title: "Number Field(Inches/mm)",
                             values: [],
                             field_value: [
                                 {
                                     text: "",
                                     value: ""
                                 }
                             ]
                         }
                    ]
                }
        ]
    }
}


update(info){
  console.log("DATA HERE",info)
  this.data = info;
}
next(id){
    alert("called")
    if (id==19){
        return [
            {
                product_id: 19,
                block_id: 66,
                title: "Default",
                sub_title: "",
                fields:[
                    {
                        field_id: 437,
                        product_id: 19,
                        block_id: 66,
                         field_name: "test",
                        field_type: "textbox",
                         input_name: "number",
                         title: "Number Field(Inches/mm)",
                         values: [],
                         field_value: [
                             {
                                 text: "",
                                 value: ""
                             }
                         ]
                     },
                     {
                        field_id: 437,
                        product_id: 19,
                        block_id: 66,
                         field_name: "test",
                        field_type: "textbox",
                         input_name: "number",
                         title: "Number Field(Inches/mm)",
                         values: [],
                         field_value: [
                             {
                                 text: "",
                                 value: ""
                             }
                         ]
                     }
                ]
            },
            {
                product_id: 19,
               block_id: 67,
                title: "PROCESS",
                sub_title: "Test2",
                fields:[
                    {
                        field_id: 437,
                        product_id: 19,
                        block_id: 66,
                         field_name: "test",
                        field_type: "textbox",
                         input_name: "number",
                         title: "Number Field(Inches/mm)",
                         values: [],
                         field_value: [
                             {
                                 text: "",
                                 value: ""
                             }
                         ]
                     },
                     {
                        field_id: 437,
                        product_id: 20,
                        block_id: 66,
                         field_name: "test",
                        field_type: "textbox",
                         input_name: "number",
                         title: "Number Field(Inches/mm)",
                         values: [],
                         field_value: [
                             {
                                 text: "",
                                 value: ""
                             }
                         ]
                     }
                ]
            }
        ]
    }
else if(id==20){
    return [
        {
            product_id: 20,
            block_id: 66,
            title: "Default",
            sub_title: "",
            fields:[
                {
                    field_id: 437,
                    product_id: 20,
                    block_id: 66,
                     field_name: "test",
                    field_type: "textbox",
                     input_name: "number",
                     title: "Number Field(Inches/mm)",
                     values: [],
                     field_value: [
                         {
                             text: "",
                             value: ""
                         }
                     ]
                 },
                 {
                    field_id: 437,
                    product_id: 20,
                    block_id: 66,
                     field_name: "test",
                    field_type: "textbox",
                     input_name: "number",
                     title: "Number Field(Inches/mm)",
                     values: [],
                     field_value: [
                         {
                             text: "",
                             value: ""
                         }
                     ]
                 }
            ]
        },
        {
            product_id: 20,
           block_id: 67,
            title: "PROCESS",
            sub_title: "Test2",
            fields:[
                {
                    field_id: 437,
                    product_id: 20,
                    block_id: 66,
                     field_name: "test",
                    field_type: "textbox",
                     input_name: "number",
                     title: "Number Field(Inches/mm)",
                     values: [],
                     field_value: [
                         {
                             text: "",
                             value: ""
                         }
                     ]
                 },
                 {
                    field_id: 437,
                    product_id: 20,
                    block_id: 66,
                     field_name: "test",
                    field_type: "textbox",
                     input_name: "number",
                     title: "Number Field(Inches/mm)",
                     values: [],
                     field_value: [
                         {
                             text: "",
                             value: ""
                         }
                     ]
                 }
            ]
        }
    ]

}
}
  selected(id, field) {
    if (id != 0) {
      console.log("On service before update", id);
      this.selectedBlock = id;
      console.log("On service after update", this.selectedBlock);
      this.extraBlock = '';
    }
    else {
      console.log("On service before update id=0", id, field);
      this.selectedBlock = id;
      this.extraBlock = field;
      console.log("On service after update id=0", this.selectedBlock, this.extraBlock);
    }
  }


}
