
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Broadcaster } from './Broadcaster';
import { ToastrService } from 'ngx-toastr';
@Injectable()
export class  AuthService {
  constructor(private myRoute: Router, private broadcaster: Broadcaster,private toastr: ToastrService) { }
  sendToken(access,refresh) {
      if (access) {
        // console.log("ACCESS",access)
        // localStorage.setItem('x-access-token', access).subscribe(() => { }, () => { });
        localStorage.setItem('x-access-token', access);
      }
        
  
      if (refresh) {
        // console.log("REFRESH",refresh)
        // localStorage.setItem('x-refresh-token', refresh).subscribe(() => { }, () => { });
        localStorage.setItem('x-refresh-token', refresh);
      }
      
  }
  getToken() {
    // return localStorage.getItem("LoggedInUser")  
     return localStorage.getItem('x-access-token')
  }
  isLoggednIn() {
    // console.log(this.getToken(), 'test');
    // let data = this.getToken() !== null;
    // console.log(data, 'data authtoken');
    return this.getToken() !== null;
  }
  isBuyerLoggednIn() {
    // console.log(this.getToken(), 'test');
    // let data = this.getToken() !== null;
    // console.log(data, 'data authtoken');
    if(this.getToken() !== null){
      if(JSON.parse(localStorage.getItem("user-data")).user_type == 2){
        return true;
      }
      else{
        return false;
      };
    }
    else{
      return false;
    }
  }
  isSellerLoggednIn() {
    // console.log(this.getToken(), 'test');
    // let data = this.getToken() !== null;
    // console.log(data, 'data authtoken');
    if(this.getToken() !== null){
      if(JSON.parse(localStorage.getItem("user-data")).user_type == 1){
        return true;
      }
      else{
        return false;
      };
    }
    else{
      return false;
    }
  }
  logout(i) {
    // this.localStorage.removeItem('x-access-token').subscribe(() => {
      localStorage.clear();
      this.broadcaster.broadcast('DATA', 'data changed');
      // .subscribe(() => {
        if(i==1)
        {this.myRoute.navigate(["home"]);
        this.toastr.success( 'Logged Out!');}
        else if(i==2)
        this.myRoute.navigate(["login"]);

      // });
    // });
    

  }
  login(headers, data) {
    this.sendToken(headers.get("x-access-token"), headers.get("x-refresh-token"));
    localStorage.setItem('user-type',data.user_type)
    this.broadcaster.broadcast('DATA', 'data changed');
    // this.localStorage.setItem('user-data',data.data).subscribe(() => { }, () => { });
    // console.log("dataaa", data.data)
    localStorage.setItem('user-data',JSON.stringify(data.data));
    // this.toastr.success( data.message);
    this.toastr.success(data.message);
   if(data.data.user_type==1){
    this.myRoute.navigate(["seller"]);
   }
   else if(data.data.user_type==2){
     if(JSON.parse(localStorage.getItem('formdata')))
     {
      this.myRoute.navigate(["matches"]);
     }
     else{
      this.myRoute.navigate(["buyer"]);
   //this.myRoute.navigate(["productsview"]);
     }
    
   }
  

  }
  register(headers, data, message) {
    this.sendToken(headers.get("x-access-token"), headers.get("x-refresh-token"));
    // localStorage.setItem('user-type',data.userdata.user_type)
    // this.localStorage.setItem('user-data',data.data).subscribe(() => { }, () => { });
    // console.log("dataaa", data.userdata)
    // localStorage.setItem('user-data',JSON.stringify(data.userdata));
  //  if(data.userdata.user_type==1){
    //  this.myRoute.navigate(["seller"]);
  //  }
  //  else{
    this.myRoute.navigate(["/"]);
  //  }
   this.toastr.success( message);

  }

  public getAccessToken() {
    return localStorage.getItem('x-access-token')

}
}