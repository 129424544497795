import { Component, OnInit } from '@angular/core';
import { OthersService } from '../../shared/services/others.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-cms',
  templateUrl: './cms.component.html',
  styleUrls: ['./cms.component.scss']
})
export class CmsComponent implements OnInit {
  body: any;
  constructor(private otherservice: OthersService, public route:ActivatedRoute,  private spinner: NgxSpinnerService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.route.queryParams.subscribe( params =>{ console.log("PARAMMSS",params)
    this.spinner.show();
  let name = params['name'];
  console.log("NAME",name)
  this.otherservice.cmsPage(name)
  .subscribe((result) => {
    setTimeout(() => {
      /** spinner ends after 3 seconds */
      this.spinner.hide();
  }, 250);
    console.log("Response",result)
    if(result.status){
      this.body=result.data.page.content
    }
  else{

  }
})
  
  });
    // let name = this.route.snapshot.paramMap.get('name');

}
}

