import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { ParallaxModule, ParallaxConfig } from 'ngx-parallax';
// import {NgbTabsetConfig} from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule} from '@angular/forms';
import { FormsModule} from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';



import { AppComponent } from './app.component';
import { NavindexComponent } from './elements/navindex/navindex.component';
import { FooterComponent } from './elements/footer/footer.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { SignupComponent } from './pages/signup/signup.component';
import { QuotelistComponent } from './elements/quotelist/quotelist.component';
import { PassportService } from './shared/services/passport.service';
import { AuthService } from './shared/services/auth.service';
import { Broadcaster } from './shared/services/Broadcaster';
import { HttpClientModule } from '@angular/common/http';

import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxCaptchaModule } from 'ngx-captcha';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CmsComponent } from './pages/cms/cms.component';
import { TextMaskModule } from 'angular2-text-mask';
import { ArchwizardModule } from 'angular-archwizard';
import { EquipmentformModule } from './pages/equipmentform/equipmentform.module';
import { BreadcrumbModule } from './elements/breadcrumb/breadcrumb.module';
import { McBreadcrumbsModule } from 'ngx-breadcrumbs';
import { ProductListComponent } from './pages/product-list/product-list.component';
import { IntegratedLineComponent } from './pages/integrated-line/integrated-line.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { HelpComponent } from './pages/help/help.component';
import { LocalstorageService } from './shared/services/localstorage.service';
import { ProductViewComponent } from  './pages/product-view/product-view.component';
import { FilterPipe } from './shared/filter.pipe';
import { SecondnavComponent } from './elements/secondnav/secondnav.component';
import { SearchPipe } from './shared/services/search.pipe';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';






@NgModule({
  declarations: [
    AppComponent,
    NavindexComponent,
    FooterComponent,
    HomeComponent,
    LoginComponent,
    SignupComponent,
    QuotelistComponent,
    CmsComponent,
    ProductListComponent,
    IntegratedLineComponent,
    HelpComponent,
   ProductViewComponent,
   FilterPipe,
   SecondnavComponent,
   SearchPipe,
  
  
   


   

  ],
  imports: [
        BrowserModule,
    NgbModule.forRoot(),
    AppRoutingModule,
    ParallaxModule,
    ReactiveFormsModule,
    FormsModule,
    NgxSpinnerModule,
    HttpClientModule,
    BrowserAnimationsModule, // required animations module
    NgMultiSelectDropDownModule.forRoot(),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    NgxCaptchaModule,
    SlickCarouselModule,
    TextMaskModule,
    ArchwizardModule,
    NgCircleProgressModule.forRoot({
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: '#78C000',
      innerStrokeColor: '#C7E596',
      animationDuration: 300,
}),
    EquipmentformModule,
    BreadcrumbModule,
    McBreadcrumbsModule.forRoot()
    
  
    
    
  ],
 
  providers: [PassportService,AuthService,Broadcaster,LocalstorageService],
  bootstrap: [AppComponent]
})
export class AppModule { }
