import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Broadcaster } from 'src/app/shared/services/Broadcaster';


@Component({
  selector: 'app-navindex',
  templateUrl: './navindex.component.html',
  styleUrls: ['./navindex.component.scss']
})
export class NavindexComponent implements OnInit {
  isCollapsed = true;
log: Boolean = false;
token: any;
hideSeller: Boolean = true;
hideBuyer: Boolean = true;
userData:any = {};

  constructor(private router: Router, private auth: AuthService, private broadcaster: Broadcaster) { }

  ngOnInit() {
    this.userData =JSON.parse(localStorage.getItem("user-data"));
    if(JSON.parse(localStorage.getItem("user-data"))){
      if(JSON.parse(localStorage.getItem("user-data")).user_type == 1){
       this.hideSeller = false  ;
      }
      else{
        this.hideSeller = true;
      };
      if(JSON.parse(localStorage.getItem("user-data")).user_type == 2){
        this.hideBuyer = false  ;
      }
      else{
       this.hideBuyer = true;
      };
    }
    else{
      this.hideSeller = true;
    this.hideBuyer = true;
    }

 

    this.broadcaster.on<string>('DATA')
    .subscribe(message => {
      console.log("IN BROADCASTER!!!!!!",message)
// if(JSON.parse(localStorage.getItem("user-data"))){
//   console.log("YO iN")
//   if(JSON.parse(localStorage.getItem("user-data")).user_type == 1){
//     this.hideSeller = true  ;
//   }
//   else{
//     this.hideSeller = false;
//   };
//   if(JSON.parse(localStorage.getItem("user-data")).user_type == 2){
//     this.hideBuyer = true  ;
//   }
//   else{
//     this.hideBuyer = false;
//   };
// }
// else{ 
//   console.log("YO iN")
//   this.hideSeller = true;
// this.hideBuyer = true;
// }
this.checkLog();
    });

    this.checkLog();
    
  }
  gotoPage(name: string) {
    console.log(name)
    this.router.navigate(['/cms'], { queryParams: { name: name } });
  }

  logout(){
    
    this.auth.logout(1);
    this.hideSeller = true;
    this.hideBuyer = true;
    this.userData = null;
    this.checkLog();
       }
       checkLog(){
        this.token = this.auth.getToken()
          if(this.token){
            console.log("1")
            this.log = true;
          }
          else{
            console.log("2")
            this.log = false;
          }
       }
    }

  


