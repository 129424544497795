import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PassportService } from '../../shared/services/passport.service';
import { PasswordValidation } from '../../shared/directives/validation.directive';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/auth.service';
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})

  export class SignupComponent implements OnInit {

    public submitted = false;
    buyerForm: FormGroup;
    public formError = '';
    public formSucess = '';
    public phNoMask = [ /[1-9]/, /\d/, /\d/,'-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    // public phNoMask=[/d{3}-/d{3}-/d{4}$]
    public siteKey = '6Lf_bescAAAAAAnPffkHND5grfaxe6Pr_1C6UCV_';
    private regularExpression = /^(?=.*[A-Z])(?=.*[-*@!#%&()$/=?¡+.´^~{}])[a-zA-Z0-9-*@!#%&()$/=?¡+.´^~{}]{8,20}$/;
  auth: any;
  buyer: any;

  
    constructor(private router: Router, public toastr: ToastrService,
      private passService: PassportService,private token: AuthService) { }

    ngOnInit(){
      window.scrollTo(0, 0);
      this.buyerForm = new FormGroup({
  
        'user_type': new FormControl('', {
          validators: [Validators.required],
          updateOn: 'change'
        }),
        'first_name': new FormControl('', {
          validators: [Validators.required],
          updateOn: 'change'
        }),
        'last_name': new FormControl('', {
          validators: [Validators.required],
          updateOn: 'change'
        }),
        'email': new FormControl("", {
          validators: [Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)],
          updateOn: 'change'
        }),
        'password': new FormControl('', {
          validators: [Validators.required,PasswordValidation.PoorPasswordValidator,Validators.pattern('(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$')],
          updateOn: 'change'
        }),
        'repeat': new FormControl('', {
          validators: [Validators.required],
          updateOn: 'change'
        }),
        'company_name': new FormControl('', {
          validators: [Validators.required],
          updateOn: 'change'
        }),
        'job_title': new FormControl('', {
          validators: [Validators.required],
          updateOn: 'change'
        }),
        'company_url': new FormControl('', {
          validators: [Validators.required],
          updateOn: 'change'
        }),
        'company_phone': new FormControl('', {
          validators: [Validators.required,Validators.pattern(/^\d{3}-\d{3}-\d{4}$/)],
          updateOn: 'change'
        }),
       // 'recaptchaReactive': new FormControl('', { validators: [Validators.required] }),  
      }, {
          validators: PasswordValidation.MatchPassword
        });
    }

    isFieldInvalid(field: string) {
      if (this.submitted && field == 'user_type') {
        // console.log("yoo", this.buyerForm.get(field).invalid)
      }
      if(this.submitted && this.buyerForm.get(field).invalid && (this.buyerForm.get(field).dirty || this.buyerForm.get(field).touched) )
      if(field=='recaptchaReactive')
      return true
      else
      return "error"
    }

    isPasswordInvalid(){
      if(this.submitted&&this.buyerForm.get('password').invalid){
        // console.log("YYYs",PasswordValidation.PoorPasswordValidator(this.buyerForm.get('password')))
        return PasswordValidation.PoorPasswordValidator(this.buyerForm.get('password')).poorPassword
      }
      else{
        return ''
      }
    }
  
    get password() {
      return this.buyerForm.get('password');
    }

    toggleWithP(tooltip,tip){
    let PValue=  this.buyerForm.get('password').value
    console.log("P Value+--+-+-+",PValue);
    if(PValue===''){
      tooltip.open({tip})
    }
    }
    toggleWithCP(tooltip,tip){
    let CPValue=  this.buyerForm.get('repeat').value
    console.log("CP Value+--+-+-+",CPValue);
    if(CPValue===''){
      tooltip.open({tip})
    }
    }

    tooltipClose(tooltip){
      tooltip.close()
    }

    signup() {
      console.log("BuyerForm+-+--+",this.buyerForm);
      
      if (this.buyerForm.controls.first_name.invalid) {
        this.markAsDirtyAndTouched();
        this.toastr.success('Message Warning!', 'Please enter name', {
          timeOut: 3000,
        });return false;
      }
      if (!this.buyerForm.controls.email.value) {
        this.markAsDirtyAndTouched();
        this.toastr.success('Message Warning!', 'Please enter email', {
          timeOut: 3000,
        });return false;
      }
      if (this.buyerForm.controls.email.invalid) {
        this.markAsDirtyAndTouched();
        this.toastr.success('Message Warning!', 'Please enter valid email', {
          timeOut: 3000,
        });return false;
      }

      if (!this.buyerForm.controls.company_name.value) {
        this.markAsDirtyAndTouched();
        this.toastr.success('Message Warning!', 'Please enter company name', {
          timeOut: 3000,
        });return false;
      }

      if (!this.buyerForm.controls.company_phone.value) {
        this.markAsDirtyAndTouched();
        this.toastr.success('Message Warning!', 'Please enter phone number', {
          timeOut: 3000,
        });return false;
      }

      if (!this.buyerForm.controls.company_url.value) {
        this.markAsDirtyAndTouched();
        this.toastr.success('Message Warning!', 'Please enter company website', {
          timeOut: 3000,
        });return false;
      }

      if (!this.buyerForm.controls.job_title.value) {
        this.markAsDirtyAndTouched();
        this.toastr.success('Message Warning!', 'Please enter job title', {
          timeOut: 3000,
        });return false;
      }
      if (parseInt(this.buyerForm.get('company_phone').value) == 0) {
        this.markAsDirtyAndTouched();
        this.toastr.success('Message Warning!', 'This is invalid phone number', {
          timeOut: 3000,
        });return false;
      }
      if (!this.buyerForm.controls.password.value) {
        this.markAsDirtyAndTouched();
        this.toastr.success('Message Warning!', 'Please enter password', {
          timeOut: 3000,
        });return false;
      }
      if (this.buyerForm.controls.password.invalid) {
        this.markAsDirtyAndTouched();
        this.toastr.success('Message Warning!', 'The password needs to have at least 8 to 20 characters', {
          timeOut: 3000,
        });return false;
      }
      if (!this.regularExpression.test(this.buyerForm.get('password').value)) {
        this.markAsDirtyAndTouched();
        this.toastr.success('Message Warning!', 'The password needs to have at least one capital letter and one special character', {
          timeOut: 3000,
        });return false;
        
      }
      this.submitted =  true;
      if (this.buyerForm.valid) {
      let data = this.buyerForm.getRawValue();
      this.passService.postDataApi('register', data)
      .subscribe(
        result => {
          if (result) {
            console.log(result)
            this.formError = '';
            this.formSucess = result.message;
            this.toastr.success('success', this.formSucess, {
              timeOut: 3000,
            });
            this.buyerForm.reset();
            this.submitted = false;
            this.token.register(result.headers,result.data,result.message);
          } else {
            this.formSucess = '';
            this.formError = result.body.message;
            this.toastr.warning(result.body.message);
          }
        });
    } else {
          PasswordValidation.validateAllFormFields(this.buyerForm);
      }
    }

    markAsDirtyAndTouched = (): void => {
      this.buyerForm.get('first_name').markAsDirty({ onlySelf: true });
      this.buyerForm.get('email').markAsDirty({ onlySelf: true });
      this.buyerForm.get('company_phone').markAsDirty({ onlySelf: true });
      this.buyerForm.get('password').markAsDirty({ onlySelf: true });
      this.buyerForm.get('first_name').markAsTouched({ onlySelf: true });
      this.buyerForm.get('email').markAsTouched({ onlySelf: true });
      this.buyerForm.get('company_phone').markAsTouched({ onlySelf: true });
    }
    // signup() { 
    //   //debugger;
    //   this.submitted =  true;
    //   if (this.buyerForm.valid) {
    //     let data = this.buyerForm.getRawValue();
    //      this.passService.register(this.buyerForm.value)
    //       .subscribe((result) => {
    //        console.log('REGISTER',result);
    //         if (result) {
    //           this.formError = '';
    //           this.formSucess = result.message;
    //           this.buyerForm.reset();
    //           this.submitted = false;
    //           this.token.register(result.headers,result.body.data,result.message);
    //           this.auth.setLogin(result.data.headers);
    //           this.auth.setUserData(result.data.userdata);
    //           const role = result.data.userdata.user_type;
    //           this.auth.setRole(role);
    //           const percentage = result.data.userdata.profile_completion_score;
    //           this.auth.setPercent(percentage);
  
    //           setTimeout(() => {
    //             if(this.buyer.user_type == '1'){
    //             this.router.navigate(['/seller']);
    //             }
    //           }, 100);
    //         } else {
    //           this.formSucess = '';
    //           this.formError = result.body.message;
    //         }
    //       });
    //   } else {
    //     PasswordValidation.validateAllFormFields(this.buyerForm);
    //   }
    // }
  
    gotoPage(name: string) {
      console.log(name)
      this.router.navigate(['/cms'], { queryParams: { name: name } });
    }
  }
  
