import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {
  id: any;
  imgUrl
  brocUrl
  data:any ={} ;
  
    constructor(private route: ActivatedRoute, private router: Router, private spinner: NgxSpinnerService, private apiservice: ApiService) { }
    

  
    ngOnInit() {
      this.spinner.show();
      window.scrollTo(0, 0)
        this.route.params.subscribe((params) => {
  
          this.id = params.id
  
            this.apiservice._post('individual_product_view',{product_id:this.id}).subscribe((result)=>{
              if(result.status){
                if(result.data){
                console.log("DATAAA",result.data)
                this.data = result.data
                this.brocUrl = result.brochure_url
                this.imgUrl = result.image_url
                }
                    this.spinner.hide();
              }
                  })
                })
              }
              download(url){
                window.open(this.brocUrl+url);
                  }

}

