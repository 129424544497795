import { AbstractControl, Validator, ValidatorFn, FormGroup, FormControl } from '@angular/forms';

export class PasswordValidation {

  static PoorPasswordValidator(control: AbstractControl) {
    const password = control.value || '';
    let re;
    if (password.length < 6) {
      return { 'poorPassword': 'Password must contain at least six characters!' };
    }
    re = /[0-9]/;
    if (!re.test(password)) {
      return { 'poorPassword': 'Password must contain at least one number (0-9)!' };
    }
    /* re = /[a-z]/;
    if (!re.test(password)) {
      return { 'poorPassword': 'Password must contain at least one lowercase letter (a-z)!' };
    }
    re = /[A-Z]/;
    if (!re.test(password)) {
      return { 'poorPassword': 'Password must contain at least one uppercase letter (A-Z)!' };
    } */
    re = /[a-zA-Z]/;
    if (!re.test(password)) {
      return { 'poorPassword': 'Password must contain at least one letter!' };
    }
    re = /[!@#$%^&*]/;
    if (!re.test(password)) {
      return { 'poorPassword': 'Password must contain at least one special character!' };
    }
    return null;
  }

  static MatchPassword(control: AbstractControl) {
    const password = control.get('password').value; // to get value in input tag
    const confirmPassword = control.get('repeat').value; // to get value in input tag
    if (password !== confirmPassword) {
      control.get('repeat').setErrors({ MatchPassword: true });
    } else {
      return null;
    }
  }

  static validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
