import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//import { ArchwizardModule } from 'angular-archwizard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EquipmentformRoutingModule } from './equipmentform-routing.module';
import { EquipmentformComponent } from './equipmentform.component';
import { BreadcrumbModule } from 'src/app/elements/breadcrumb/breadcrumb.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';


import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

//import { NgbdPopoverTplcontent } from './popover-tplcontent';


@NgModule({
  declarations: [
    EquipmentformComponent,
  ],
  imports: [
    CommonModule,
    //ArchwizardModule,
    BreadcrumbModule,
    EquipmentformRoutingModule,
    FormsModule, 
    ReactiveFormsModule,
    NgMultiSelectDropDownModule.forRoot(),
     NgbModule,

  ]
})
export class EquipmentformModule { }
