import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable ,  of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Auth } from '../classes/auth';
import { ResetPassword } from '../classes/reset-password';
import { Buyer } from '../classes/buyer';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
// import { Http } from '@angular/http';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class PassportService {

  //private baseUrl = environment.baseUrl+'client/';  // URL to web api
  public baseUrl: string = environment.baseUrl +'client/';
  constructor(
    private http: HttpClient ,private tostr: ToastrService) { }

  /** Patient Signup */
  signup(buyer: Buyer): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'register', buyer, httpOptions)
      .pipe(
        tap(heroes => console.log(heroes,`buyer signup`)),
        catchError(this.handleError('signup', { status: false, message: 'Network Error!'}))
      );
  }

  /** Doctor Signup */
  // signupDoctor(doctor: Doctor): Observable<any> {
  //   return this.http.post<any>(this.baseUrl + 'doctor_signup', doctor, httpOptions)
  //     .pipe(
  //       tap(heroes => console.log(`doctor signup`)),
  //       catchError(this.handleError('signupDoctor', { status: false, message: 'Network Error!' }))
  //     );
  // }

  /** Common Login */
  authenticate(aut): Observable<any> {
    let apiUrl = this.baseUrl + 'login';
    return this.http.post<any>(apiUrl,aut, { headers: httpOptions.headers, observe: 'response' } )
      .pipe(
        tap(heroes => console.log(`sign in`, heroes)),
        catchError(this.handleError('authenticate', { status: false, message: 'Network Error!'}))
      );
  }
    /** Common Login */
    register(data): Observable<any> {
      let apiUrl = this.baseUrl + 'passports/register';
      const headers = this.getHeadersForMultipart();
      return this.http.post<any>(apiUrl,data,{ headers: headers } )
        .pipe(
          tap(heroes => console.log(`register`, heroes)),
          catchError(this.handleError('authenticate', { status: false, message: 'Network Error!'}))
        );
    }
    
    postDataApi(url, input): Observable<any> {
      let apiUrl = this.baseUrl;
      return this.http.post<any>(apiUrl + url, input, { headers: httpOptions.headers })
      .pipe(
        tap(heroes => console.log(`register`, heroes)),
        catchError(this.handleError('authenticate', { status: false, message: 'Network Error!'}))
      );
      
    }

    getHeadersForMultipart() {
      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
      //headers = headers.append('Content-Type', 'application/json');
      //headers = headers.append('Authorization', 'Bearer ' + token);
      return headers;
    }

  /** Forgot Password */
  forgot(email: string): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'forgot', { email: email }, httpOptions)
      .pipe(
        tap(heroes => console.log(`forgot`)),
        catchError(this.handleError('forgot', { status: false, message: 'Network Error!' }))
      );
  }

  /** Reset Password */
  reset(email:string,password:string,reset_code:string): Observable<any> {
    console.log("DATTATAA",email,password,reset_code)
    return this.http.post<any>(this.baseUrl + 'reset', {email:email,password:password,reset_code:reset_code}, httpOptions)
      .pipe(
        tap(heroes => console.log(`reset`)),
        catchError(this.handleError('reset', { status: false, message: 'Network Error!' }))
      );
  }

  /** Get New Token */
  generateToken(): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('xAccessToken'),
        'x-refresh-token': localStorage.getItem('xRefreshToken')
      })
    };

    return this.http.get<any>(this.baseUrl + 'token_web', options)
      .pipe(
        tap(heroes => console.log(`reset`)),
        catchError(this.handleError('getToken', { status: false, message: 'Network Error!' }))
      );
  }

  /** Verify Otp */
  verifyOtp(otp: number): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('xAccessToken')
      })
    };

    return this.http.post(this.baseUrl + 'verify_otp', { otp: otp }, options)
      .pipe(
        tap(heroes => console.log(`fetched`)),
        catchError(this.handleError('verifyOtp', { status: false, message: 'Network Error!' }))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      console.log("API ERROR!")
      this.tostr.error('Unexpected Error!')
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

}
