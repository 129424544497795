import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductsService } from 'src/app/shared/services/products.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { WizardComponent } from 'angular-archwizard';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-integrated-line',
  templateUrl: './integrated-line.component.html',
  styleUrls: ['./integrated-line.component.scss']
})
export class IntegratedLineComponent implements OnInit {
  public blockFields: Array<any> = [];
  public displayField: Array<any> = [];
  public childBlocks: Array<any> = [];
  public childProductField: Array<any> = [];
  public processData: Array<any> = [];
  public childBlockName: any;
  public child: Boolean = false;
  public processid: Array<any> = [];
  public min: string = "";
  public max: string = "";
  public total: number = 0;
  public newHash: any;
  public fileToUpload: File = null;
  public id: any;
  public type: any;
  public editSection: Boolean = false;
  public valid: Boolean
  public unit: Boolean
  public selectedBlockIndex: Number;
  public data: any = {};
  public send: any = {};
  public percentage: any;
  public query: Array<any> = [];
  public Intequery: Array<any> = [];
  public modalData:any;
  goNext:Boolean = false;
  activeTabid:any;
  minmax:Boolean = false

  constructor(private route: ActivatedRoute, private productService: ProductsService, private apiservice: ApiService , private spinner: NgxSpinnerService, private cdr: ChangeDetectorRef, private router: Router,  private modalService: NgbModal,private config: NgbModalConfig, private tostr:ToastrService) {config.backdrop = 'static'; }

  @ViewChild('content') private content;
  @ViewChild('wizard') wizard: WizardComponent;
  
  ngOnInit() {
    this.spinner.show();
    this.route.params.subscribe((params) => {

      var numberPattern = /\d+/g;
      this.id = params.id
      console.log("IDDDDDAD", params.id, this.id)
      this.apiservice._post('integrated_product_view',{product_id:this.id})
        .subscribe((result) => {
          if(result.status){
          console.log("BEFORE CONV", result)
          this.commonInit(result);
         
          }
          else{
            this.tostr.error(result.message);
            this.router.navigateByUrl('')
          }
          
          this.spinner.hide();
        });
    });
  }

  commonInit(result) {

    this.data = result.data
    this.activeTabid = this.data.individual_products[0].productInfo.blocks[0].id
    this.selectedBlockIndex = this.data.blocks[0].id;
    console.log("Active tab",this.activeTabid)

    this.data.individual_products.forEach(element => {
        element.productInfo.blocks.forEach((block) => {
          block.fields.forEach((field,i,mainArray) => {
            
        if(i+1 != mainArray.length)
        if (field.title == mainArray[i + 1].title) {
          console.log("FILEDS",i,field,mainArray[i + 1])
          field.min_field_type = mainArray[i + 1].field_type;
          field.min_field_type.id = mainArray[i + 1].id
          mainArray.splice(i + 1, 1);
        }
    });  
  });  
  });

    this.data.blocks.forEach(element => {
      element.fields.forEach((field,i,mainArray) => {
        if(i+1 != mainArray.length)
        if (field.title == mainArray[i + 1].title) {
          field.min_field_type = (mainArray[i + 1].field_type);
          field.min_field_type.id = mainArray[i + 1].id
          mainArray.splice(i + 1, 1);
        }
      });
      
    });
    this.blockFields = this.data.blocks;
    if (!this.blockFields.find(y => y.block_id == 0)) {
      this.blockFields.push({
        title: "DOCUMENTS",
        id: 1,
        
      },
        {
          title: "REVIEW",
          id: 2,
         
        })
        this.cdr.detectChanges();
    }
    this.cdr.detectChanges();
    console.log("RESULTTT", this.data)
    // this.open(this.content);
    this.spinner.hide();

  }

  checkClass(id){
    if(id == this.selectedBlockIndex){
      return "active"
    }
      }

   /**
   * upload section of files
   * @param files 
   */
  handleFileInput(files: FileList) {
    this.fileToUpload = files[0];
    this.uploadFileToActivity();
  }
  /**
   * upload function 
   */
  uploadFileToActivity() {
    this.productService.postFile(this.fileToUpload).subscribe(data => {
      // do something, if upload success
    }, error => {
      console.log(error);
    });
  }
/**
 * roundoff function
 */
  roundSingle(i,j,k) {
    console.log("IN")
    if( this.data.productFields[j].field_value[0].value)
    this.data.productFields[j].field_value[0].value = parseFloat(this.data.productFields[j].field_value[0].value).toFixed(2)
    else
  {
    this.data.productFields[j].field_value[0].value = ''
  }
  }
  roundSingleT(i,j,k) {
    //   console.log("IN")
      if(this.data.individual_products[i].productInfo.blocks[j].fields[k].field_type.value)
    this.data.individual_products[i].productInfo.blocks[j].fields[k].field_type.value = parseFloat(this.data.individual_products[i].productInfo.blocks[j].fields[k].field_type.value).toFixed(2)    //   else
    {
      this.data.individual_products[i].productInfo.blocks[j].fields[k].field_type.value = ''
    }
    }
  /**
   * roundoff
   */
  roundDouble(j,i) {
    console.log("IN")
    if( this.data.blocks[j].fields[i].value)
    this.data.productFields[j].field_value[i].value = parseFloat(this.data.productFields[j].field_value[i].value).toFixed(2)
  else
  {
    this.data.productFields[j].field_value[i].value = ''
  }
  }
  roundDoubleT(i,j,k,l) {
      console.log("IN")
      if(l==0)
      {
      if(this.data.individual_products[i].productInfo.blocks[j].fields[k].field_type.value)
      this.data.individual_products[i].productInfo.blocks[j].fields[k].field_type.value = parseFloat(this.data.individual_products[i].productInfo.blocks[j].fields[k].field_type.value).toFixed(2)
      else
      this.data.individual_products[i].productInfo.blocks[j].fields[k].field_type.value = ''
      }
     else if(l==1)
      {
        if(this.data.individual_products[i].productInfo.blocks[j].fields[k].min_field_type.value)
      this.data.individual_products[i].productInfo.blocks[j].fields[k].min_field_type.value = parseFloat(this.data.individual_products[i].productInfo.blocks[j].fields[k].min_field_type.value).toFixed(2)
      else
      this.data.individual_products[i].productInfo.blocks[j].fields[k].min_field_type.value = ''
      }
    }

    MinMaxVal(j,i){
      if(this.data.productFields[j].field_value[i].text == "Min"){
        if(i==0){
        if(parseFloat(this.data.productFields[j].field_value[0].value) >= parseFloat(this.data.productFields[j].field_value[1].value))
        {
         return 'error'
        }
      //    if(this.data.productFields[j].field_value[0].value)
      // {
      //   if((!this.data.productFields[j].field_value[1].value)&&this.goNext)
      //   return 'error'
      // }
      }
      if(i==1){
      if(parseFloat(this.data.productFields[j].field_value[0].value) <= parseFloat(this.data.productFields[j].field_value[1].value)){
        return 'error'
      }
     
      // if(this.data.productFields[j].field_value[1].value)
      // {
      //   if((!this.data.productFields[j].field_value[0].value)&&this.goNext)
      //   return 'error'
      // }
      }
    }
      if(this.data.productFields[j].field_value[i].text == "Max"){
        if(i==0){
        if(parseFloat(this.data.productFields[j].field_value[0].value) <= parseFloat(this.data.productFields[j].field_value[1].value))
        {
         return 'error'
        }
        // if(this.data.productFields[j].field_value[0].value)
        // {
        //   if((!this.data.productFields[j].field_value[1].value)&&this.goNext)
        //   return 'error'
        // }
      }
     
      if(i==1){
      if(parseFloat(this.data.productFields[j].field_value[0].value) >= parseFloat(this.data.productFields[j].field_value[1].value)){
  
        return 'error'
      }
      // if(this.data.productFields[j].field_value[1].value)
      // {
      //   if((!this.data.productFields[j].field_value[0].value)&&this.goNext)
      //   return 'error'
      // }
      }
    }
  }

  MinMaxValT(i,j,k,l){
      if(this.data.individual_products[i].productInfo.blocks[j].fields[k].field_type.text == "Min"){
        // if(i==0){
        if(parseFloat(this.data.individual_products[i].productInfo.blocks[j].fields[k].field_type.value) >= parseFloat(this.data.individual_products[i].productInfo.blocks[j].fields[k].min_field_type.value))
        {
         return 'error'
        }
     
      // }
      // if(i==1){
      // if(parseFloat(this.data.productFields[j].field_value[0].value) <= parseFloat(this.data.productFields[j].field_value[1].value)){
      //   return 'error'
      // }
     
     
      // }
    }
      if(this.data.individual_products[i].productInfo.blocks[j].fields[k].field_type.text == "Max"){
        // if(i==0){
          if(parseFloat(this.data.individual_products[i].productInfo.blocks[j].fields[k].field_type.value) <= parseFloat(this.data.individual_products[i].productInfo.blocks[j].fields[k].min_field_type.value))
          {
           return 'error'
          }
      
      // }
     
      // if(i==1){
      // if(parseFloat(this.data.productFields[j].field_value[0].value) >= parseFloat(this.data.productFields[j].field_value[1].value)){
  
      //   return 'error'
      // }
   
      // }
    }
  }


  open(content) {
    this.modalData = this.modalService.open(content);
 }

 OpenTab(i,j,k,l){
this.data.individual_products[k].productInfo.blocks[l].fields.every(ele=>{
    if(ele.field_type.value&&ele.min_field_type.value)
    {
      
   if(ele.field_type.text=='Max')
   {
    if(ele.field_type.value&&ele.min_field_type.value)
    {
     if(ele.field_type.value > ele.min_field_type.value)
     this.minmax =  false
     else
     {
      //  console.log("ELEE", ele)
     this.minmax = true
     return false
     }
    }
    else{
      this.minmax = true
      return false

    }
   }
   else if(ele.field_type.text=='Min')
   {
    if(ele.field_type.value&&ele.min_field_type.value)
    {
    if(ele.field_type.value < ele.min_field_type.value)
    this.minmax =  false
    else
    {
      // console.log("ELEE", ele)
    this.minmax = true
    return false

    }
  }
  else{
    this.minmax = true
    return false

  }
}

  else{
    this.minmax = true
    return false

  }
}
else{
  // console.log("ONONONONO", ele)
  this.minmax =  false
}
return true
  })
console.log("MINMAX",this.minmax)
  if(!this.minmax)
  this.activeTabid = this.data.individual_products[i].productInfo.blocks[j].id
 }

gotoTab(i,j){
  this.activeTabid = this.data.individual_products[i].productInfo.blocks[j].id
}

 setTab(i,id){
  this.selectedBlockIndex = id
}

activateEdit(){
  this.editSection = true
}
 getPercentange(i,j){
  this.data.individual_products[i].productInfo.blocks[j].percentage =  (this.data.individual_products[i].productInfo.blocks[j].fields.filter(w=>(w.field_type.value!=''&&w.field_type.value!=null)).length/this.data.individual_products[i].productInfo.blocks[j].fields.length)*100
// console.log("PERCENT",this.data.individual_products[i].productInfo.blocks[j]) 
}




ngOnDestroy(){
  if(this.modalData)
  this.modalData.close();
}
}
